import { useMemo } from "react";
import {
  MenuButton,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Button,
} from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import { useFilters } from "../../../../lib/useFilters";
import { availableFilters } from "./availableFilters";

export const defaultFilters = {
  assigned: [{ key: "my-teams", display: "My teams" }],
  status: [{ key: "open", display: "Open" }],
  priority: [],
  type: [],
};

export const FilterIncidents = ({ onFiltersChange }) => {
  const { selectedFilters, handleApplyFilter, handleRemoveFilter, getFlattenedFilters } =
    useFilters("__assigf", defaultFilters, onFiltersChange);

  const flattenedFilters = useMemo(() => getFlattenedFilters(), [selectedFilters]);

  const getAvailableFiltersForGroup = (group) => {
    return availableFilters[group].filter(
      (option) => !selectedFilters[group].some((selected) => selected.key === option.key)
    );
  };

  return (
    <div className="flex-container">
      <FilterTags filters={flattenedFilters} onRemove={handleRemoveFilter} />
      {flattenedFilters.length < 13 && (
        <Menu>
          <MenuTrigger>
            <MenuButton appearance="transparent" menuIcon={<></>}>
              + Add filter
            </MenuButton>
          </MenuTrigger>
          <MenuPopover style={{ paddingLeft: "var(--spacingHorizontalMNudge)" }}>
            <FilterMenu
              availableFilters={availableFilters}
              getAvailableFiltersForGroup={getAvailableFiltersForGroup}
              onApplyFilter={handleApplyFilter}
            />
          </MenuPopover>
        </Menu>
      )}
    </div>
  );
};

const FilterMenu = ({ availableFilters, getAvailableFiltersForGroup, onApplyFilter }) => {
  const filterGroups = Object.keys(availableFilters);

  return (
    <>
      {filterGroups.map((group) => {
        const availableGroupFilters = getAvailableFiltersForGroup(group);
        if (availableGroupFilters.length === 0) return null;

        return (
          <MenuList key={group}>
            <MenuItem
              disabled
              style={{
                fontWeight: 600,
                color: "var(--colorNeutralForeground1)",
                cursor: "default",
              }}
            >
              {group.charAt(0).toUpperCase() + group.slice(1)}
            </MenuItem>
            {availableGroupFilters.map((option) => (
              <MenuItem
                key={option.key}
                onClick={() => onApplyFilter(group, option)}
                style={{ paddingLeft: "15%" }}
              >
                {option.display}
              </MenuItem>
            ))}
          </MenuList>
        );
      })}
    </>
  );
};

const FilterTags = ({ filters, onRemove }) => {
  if (filters.length === 0) return null;

  return (
    <ul className="tags-list">
      {filters.map((option) => (
        <li key={`${option.group}-${option.key}`} style={{ paddingRight: "5px" }}>
          <Button
            size="small"
            shape="circular"
            appearance="primary"
            icon={<Dismiss12Regular />}
            iconPosition="after"
            onClick={() => onRemove(option.group, option.key)}
          >
            {option.display}
          </Button>
        </li>
      ))}
    </ul>
  );
};
