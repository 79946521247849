import * as React from "react";
import { DismissRegular } from "@fluentui/react-icons";
import {
  MessageBar as FluentMessageBar,
  MessageBarActions,
  MessageBarTitle,
  MessageBarBody,
  Button,
} from "@fluentui/react-components";

export const MessageBar = ({ intent, title, body, dispatchAction }) => {
  return (
    <FluentMessageBar intent={intent} style={{ marginBottom: "1em" }} layout="multiline">
      <MessageBarBody>
        <MessageBarTitle>{title}</MessageBarTitle>
        {body}
      </MessageBarBody>
      {dispatchAction && (
        <MessageBarActions
          containerAction={
            <Button
              onClick={() => dispatchAction(title)}
              aria-label="dismiss"
              appearance="transparent"
              icon={<DismissRegular />}
            />
          }
        />
      )}
    </FluentMessageBar>
  );
};
