import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, Badge, Caption1, Subtitle1, Link } from "@fluentui/react-components";
import { Calendar20Regular } from "@fluentui/react-icons";
import { authentication } from "@microsoft/teams-js";
import config from "../../../lib/config";
import useAxios from "../../../lib/useAxios";
import { formatCurrency } from "../../../lib/common";
import { MessageGroup } from "../../../components/MessageGroup";
import { SetupPaymentMethod } from "./SetupPaymentMethod";

export function Subscription() {
  const navigate = useNavigate();
  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, true);
  const [ssoToken, setSsoToken] = useState();
  const [nextBillingInfo, setNextBillingInfo] = useState({
    plan_name: "-",
    total_amount: 0,
    plan_description: "",
    items: [{ description: "-", amount: 0 }],
  });
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
  const [cardPreview, setCardPreview] = useState({
    brand: null,
    card4: null,
    configured: undefined,
  });
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const pullBillingInfo = async () => {
      try {
        const sso_token = await authentication.getAuthToken();
        setSsoToken(sso_token);
        const { data: nextBill } = await axiosHubInstance.get("/billing/projected");
        setNextBillingInfo(nextBill);
        const { data: paymentMethod } = await axiosHubInstance.get("/billing/payment/method");
        if (paymentMethod) {
          setCardPreview({
            brand: paymentMethod.card_brand,
            card4: paymentMethod.last4,
            configured: true,
          });
        } else {
          setCardPreview((prev) => ({
            ...prev,
            configured: false,
          }));
        }
      } catch (err) {
        console.debug(err);
      }
    };

    pullBillingInfo();
  }, []);

  return (
    <div className="app-body inline-content">
      <div style={{ marginTop: "1em" }}>
        {/* Here we can include some important notifications */}
        <MessageGroup messages={messages} />
      </div>
      <div className="setup-title-container">
        <Subtitle1>Billing & Usage</Subtitle1>
        <Caption1 style={{ color: "var(--colorNeutralForeground4)" }}>
          Track your usage and upcoming charges
        </Caption1>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Card>
          {/* <CardHeader
              header={
                <Badge appearance="filled" color="success">
                  {nextBillingInfo.plan_name}
                </Badge>
              }
            /> */}

          <Subtitle1>Projected Billing</Subtitle1>

          <div className="billing-card">
            <div className="summary-container">
              {/* Billing Summary */}
              <div className="billing-summary">
                <div className="bill-grid">
                  {nextBillingInfo.items.map((billItem) => (
                    <div className="summary-row">
                      <span className="label-summary">{billItem.description}</span>
                      <span>{formatCurrency.format(billItem.amount)}</span>
                    </div>
                  ))}

                  <div className="total-row">
                    <div className="total-amount">
                      <span>Total Amount</span>
                      <span>{formatCurrency.format(nextBillingInfo.total_amount)}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Additional Information */}
              <div className="additional-info">
                <div className="info-row">
                  <Calendar20Regular />
                  <span>
                    Next billing date:{" "}
                    {nextBillingInfo.next_billing_date
                      ? new Date(nextBillingInfo.next_billing_date).toLocaleDateString()
                      : "-"}
                  </span>
                </div>
                <div className="info-description">
                  {nextBillingInfo.plan_description} You can manage your organization's technicians
                  from the{" "}
                  <Link className="link" onClick={() => navigate("../setup_teams")}>
                    Technician Teams
                  </Link>{" "}
                  session.
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      {ssoToken && cardPreview.configured != undefined && (
        <SetupPaymentMethod
          ssoToken={ssoToken}
          cardPreview={cardPreview}
          loading={loadingPaymentMethod}
          updateCardPreview={(card, loading) => {
            if (card) {
              setCardPreview(card);
            }
            setLoadingPaymentMethod(loading);
          }}
        />
      )}
    </div>
  );
}
