import axios from "axios";
import { getFromLocalStorage, saveToLocalStorage, removeFromLocalStore } from "./localStorage";
import { authentication } from "@microsoft/teams-js";
import { generateHashFromMstToken, getTeamsAuthenticatedUser, getAuthEmail } from "./common";

let isRefreshing = false;
let failedRequests = [];

const useAxios = (apiHost, withCredentials = true) => {
  const axiosInstance = axios.create({
    baseURL: apiHost,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials,
  });

  const authenticate = async () => {
    const token = await authentication.getAuthToken();
    const authInstance = axios.create({
      baseURL: apiHost,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: false,
    });
    const { data } = await authInstance.post("/auth/teams", {
      mst_token: token,
      mst_hash: generateHashFromMstToken(token),
    });

    if (data.access_token) {
      saveToLocalStorage("__atkn", data.access_token);
      axiosInstance.defaults.headers.Authorization = `Bearer ${data.access_token}`;
      return data.access_token;
    } else {
      throw new Error("Some unknown error has occurred");
    }
  };

  const isUserAuthenticated = async () => {
    if (!Boolean(getFromLocalStorage("__atkn"))) {
      return false;
    } else {
      // Validate if the storaged tokens belogs to the current teams user
      const teamsToken = await authentication.getAuthToken();
      const msUser = getTeamsAuthenticatedUser(teamsToken);
      const resolveUser = getAuthEmail();
      return msUser === resolveUser;
    }
  };

  axiosInstance.interceptors.request.use(
    async (request) => {
      if (request.withCredentials) {
        let authToken = getFromLocalStorage("__atkn");
        if (!authToken) {
          authToken = await authenticate();
        }
        if (authToken) {
          request.headers.Authorization = `Bearer ${authToken}`;
        }
      }
      return request;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      // For token expired
      if (error.response && error.response.status === 440 && !originalRequest._retry) {
        if (!isRefreshing) {
          originalRequest._retry = true;
          isRefreshing = true;
          try {
            const newToken = await authenticate();
            failedRequests.forEach((requestCallback) => requestCallback(newToken));
            failedRequests = [];
            isRefreshing = false;
            return axiosInstance(originalRequest);
          } catch (err) {
            failedRequests = [];
            isRefreshing = false;
            removeFromLocalStore("__atkn");
            console.error("Failed to generate a new token");
            return Promise.reject(error);
          }
        }

        return new Promise((resolve) => {
          failedRequests.push((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }

      if (error.response && [403].includes(error.response.status)) {
        removeFromLocalStore("__atkn");
        console.error("Permission denied");
      }

      return Promise.reject(error);
    }
  );

  return { axiosInstance, authenticate, isUserAuthenticated };
};

export default useAxios;
