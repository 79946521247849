import { jwtDecode } from "jwt-decode";
import sha256 from "js-sha256";
import { getFromLocalStorage, parseJwt } from "./localStorage";

// Random values

export const generateRandomId = (length = 12) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// Dates

export const getHumanDate = (utcDatetimeString) => {
  const localDate = new Date(utcDatetimeString + "Z");
  const seconds = Math.floor((new Date() - localDate) / 1000);
  const interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years ago";
  }
  if (interval === 1) {
    return interval + " year ago";
  }

  const months = Math.floor(seconds / 2628000);
  if (months > 1) {
    return months + " months ago";
  }
  if (months === 1) {
    return months + " month ago";
  }

  const days = Math.floor(seconds / 86400);
  if (days > 1) {
    return days + " days ago";
  }
  if (days === 1) {
    return days + " day ago";
  }

  const hours = Math.floor(seconds / 3600);
  if (hours > 1) {
    return hours + " hours ago";
  }
  if (hours === 1) {
    return hours + " hour ago";
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes > 1) {
    return minutes + " minutes ago";
  }
  if (minutes === 1) {
    return minutes + " minute ago";
  }

  return "just now";
};

export const getFullDateTime = (utcDatetimeString, asArray = false) => {
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const localDate = new Date(utcDatetimeString + "Z");
  const datePart = localDate.toLocaleDateString("en-US", dateOptions);
  const timePart = localDate.toLocaleTimeString("en-US");

  if (asArray) {
    return [datePart, timePart];
  } else {
    return `${datePart} ${timePart}`;
  }
};

export const getShortDate = (utcDatetimeString) => {
  const dateOptions = {
    month: "short",
    day: "numeric",
  };
  const localDate = new Date(utcDatetimeString + "Z");
  return localDate.toLocaleDateString("en-US", dateOptions);
};

// Teams JWT

export const getTeamsAuthenticatedUser = (msToken) => {
  const payload = jwtDecode(msToken);
  return payload["preferred_username"];
};

export const generateHashFromMstToken = (msToken) => {
  const payload = jwtDecode(msToken);
  return sha256(`${payload["iat"]}.${payload["tid"]}`);
};

export const getTenantIdFromMstToken = (msToken) => {
  const payload = jwtDecode(msToken);
  return payload["tid"];
};

export const getWSConfigClientId = (msToken) => {
  const payload = jwtDecode(msToken);
  return sha256(`${payload["tid"]}.${payload["preferred_username"]}`);
};

// Backend JWT

export const haveRole = (role) => {
  if (!role) return false;
  const authToken = getFromLocalStorage("__atkn");
  if (!authToken) return null;
  const { scopes } = parseJwt(authToken);
  return scopes.includes(role) ? role : false;
};

export const getMainRole = () => {
  const authToken = getFromLocalStorage("__atkn");
  if (!authToken) return null;
  const { scopes } = parseJwt(authToken);
  if (scopes.includes("admin")) return "admin";
  if (scopes.includes("technician")) return "technician";
  if (scopes.includes("requester")) return "requester";
  else throw Error("No role found");
};

export const getAuthName = () => {
  const authToken = getFromLocalStorage("__atkn");
  if (!authToken) return null;
  const { name } = parseJwt(authToken);
  return name;
};

export const getAuthEmail = () => {
  const authToken = getFromLocalStorage("__atkn");
  if (!authToken) return null;
  const { sub } = parseJwt(authToken);
  return sub;
};

// Files

export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export function getFileType(fileName) {
  const extension = fileName.split(".").pop().toLowerCase();
  const imageTypes = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
  const wordTypes = ["doc", "docx"];
  const txtTypes = ["txt", "rtf", "log", "config"];
  const pdfTypes = ["pdf"];
  const spreadsheetTypes = ["xls", "xlsx"];
  const csvTypes = ["csv"];
  const presentationTypes = ["ppt", "pptx"];
  const archiveTypes = ["rar", "7z", "tar", "gz"];
  const zipTypes = ["zip"];
  const videoTypes = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];
  const xmlTypes = ["xml"];
  const htmlTypes = ["html", "htm", "xhtml"];
  const exeTypes = ["exe"];
  const codeTypes = [
    "css",
    "js",
    "jsx",
    "json",
    "svg",
    "php",
    "jsp",
    "py",
    "java",
    "cpp",
    "c",
    "cs",
    "rb",
    "go",
    "swift",
    "kt",
    "rs",
    "ts",
    "scala",
    "m",
    "lua",
    "pl",
    "r",
    "sh",
    "vb",
  ];
  const audioTypes = [
    "mp3",
    "wav",
    "ogg",
    "flac",
    "aac",
    "m4a",
    "wma",
    "aiff",
    "alac",
    "mka",
    "opus",
    "ra",
    "mid",
    "amr",
    "au",
  ];

  if (imageTypes.includes(extension)) return "photo";
  if (wordTypes.includes(extension)) return "word";
  if (txtTypes.includes(extension)) return "txt";
  if (pdfTypes.includes(extension)) return "pdf";
  if (spreadsheetTypes.includes(extension)) return "spreadsheet";
  if (csvTypes.includes(extension)) return "csv";
  if (presentationTypes.includes(extension)) return "presentation";
  if (archiveTypes.includes(extension)) return "archive";
  if (zipTypes.includes(extension)) return "zip";
  if (videoTypes.includes(extension)) return "video";
  if (xmlTypes.includes(extension)) return "xml";
  if (exeTypes.includes(extension)) return "exe";
  if (htmlTypes.includes(extension)) return "html";
  if (codeTypes.includes(extension)) return "code";
  if (audioTypes.includes(extension)) return "audio";

  return "genericfile";
}

export function shortenFileName(fileName, maxLength = 20) {
  const lastDotIndex = fileName.lastIndexOf(".");
  const name = lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;
  const extension = lastDotIndex !== -1 ? fileName.slice(lastDotIndex) : "";
  if (fileName.length <= maxLength) {
    return fileName;
  }
  const maxNameLength = maxLength - extension.length - 3;
  if (maxNameLength < 1) {
    return "..." + extension;
  }
  const shortenedName = name.slice(0, maxNameLength) + "...";
  return shortenedName + extension;
}

// Arrays

export function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort((a, b) => a - b);
  const sortedArr2 = arr2.slice().sort((a, b) => a - b);

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export function arrayContainsAndExtends(arr1, arr2) {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  if (set2.size < set1.size) {
    return false;
  }

  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }

  // Si llegamos aquí, arr2 contiene todos los elementos de arr1
  // y elementos adicionales
  return true;
}

export function areSetsEqual(set1, set2) {
  if (set1.size !== set2.size) return false;
  return [...set1].every((value) => set2.has(value));
}

// Json processing

export const extractTextFromJSON = (json) => {
  let result = "";
  json = JSON.parse(json);

  function traverseObject(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        traverseObject(obj[key]);
      } else if (key === "text") {
        result += obj[key];
      }
    }
  }

  traverseObject(json);
  return result;
};

// Numbers

export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
