import { useRef, useMemo } from "react";
import { ShieldPerson20Regular, SubtractCircleFilled } from "@fluentui/react-icons";
import {
  Card,
  Tag,
  Button,
  Body1,
  Tooltip,
  Divider,
  SearchBox,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  createTableColumn,
  TableCellActions,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
} from "@fluentui/react-components";

const columnSizingOptions = {
  display_name: {
    minWidth: 120,
    defaultWidth: "30%",
  },
  email: {
    minWidth: 120,
    defaultWidth: "50%",
  },
};

const createColumns = (onRevokeAdmin) => [
  createTableColumn({
    columnId: "display_name",
    compare: (a, b) => {
      return a.display_name.localeCompare(b.display_name);
    },
    renderHeaderCell: () => {
      return "Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={<ShieldPerson20Regular />}>
          <div className="horizontal-content">
            {item.display_name}
            {item.sys_roles.includes("owner") && (
              <Tag
                size="extra-small"
                appearance="filled"
                shape="circular"
                style={{ marginLeft: "10px" }}
              >
                Owner
              </Tag>
            )}
          </div>
        </TableCellLayout>
      );
    },
  }),
  createTableColumn({
    columnId: "email",
    compare: (a, b) => {
      return a.email.localeCompare(b.email);
    },
    renderHeaderCell: () => {
      return "Email";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {item.email}
          <TableCellActions style={{ paddingRight: "1em" }}>
            <Tooltip content="Revoke admin permissions" withArrow={true}>
              <Button
                appearance="subtle"
                aria-label="more"
                icon={<SubtractCircleFilled />}
                onClick={() => onRevokeAdmin(item)}
              />
            </Tooltip>
          </TableCellActions>
        </TableCellLayout>
      );
    },
  }),
];

const createCompactColumns = (onRevokeAdmin) => [
  createTableColumn({
    columnId: "display_name",
    compare: (a, b) => {
      return a.display_name.localeCompare(b.display_name);
    },
    renderHeaderCell: () => {
      return "Admin";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={<ShieldPerson20Regular />}>
          <div className="horizontal-content">
            {item.display_name}
            {item.sys_roles.includes("owner") && (
              <Tag
                size="extra-small"
                appearance="filled"
                shape="circular"
                style={{ marginLeft: "10px" }}
              >
                Owner
              </Tag>
            )}
          </div>
          <TableCellActions style={{ paddingRight: "1em" }}>
            <Tooltip content="Revoke admin permissions" withArrow={true}>
              <Button
                appearance="subtle"
                aria-label="more"
                icon={<SubtractCircleFilled />}
                onClick={() => onRevokeAdmin(item)}
              />
            </Tooltip>
          </TableCellActions>
        </TableCellLayout>
      );
    },
  }),
];

export const AdminDataList = ({ list, compactView, actionComponent, onRevokeAdmin, onSearch }) => {
  const refMap = useRef({});
  const columns = useMemo(() => {
    return compactView ? createCompactColumns(onRevokeAdmin) : createColumns(onRevokeAdmin);
  }, [onRevokeAdmin, compactView]);

  return (
    <Card style={{ minWidth: compactView ? "100%" : "50%" }}>
      <div className="flex-row">
        {actionComponent}
        {!compactView && (
          <SearchBox
            appearance="filled-darker"
            placeholder="Search"
            onChange={(_, data) => onSearch(data.value)}
          />
        )}
      </div>
      <Divider />
      <DataGrid
        items={list}
        columns={columns}
        sortable
        data-show-checkboxes="false"
        getRowId={(item) => item.id}
        resizableColumns
        resizableColumnsOptions={{
          autoFitColumns: false,
        }}
        columnSizingOptions={columnSizingOptions}
      >
        <DataGridHeader>
          <DataGridRow
            selectionCell={{
              checkboxIndicator: { "aria-label": "Select all rows" },
            }}
          >
            {({ renderHeaderCell, columnId }, dataGrid) =>
              dataGrid.resizableColumns ? (
                <Menu openOnContext>
                  <MenuTrigger>
                    <DataGridHeaderCell ref={(el) => (refMap.current[columnId] = el)}>
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(columnId)}
                      >
                        Keyboard Column Resizing
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ) : (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )
            }
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody>
          {({ item, rowId }) => (
            <DataGridRow
              key={rowId}
              selectionCell={{
                checkboxIndicator: { "aria-label": "Select row" },
              }}
            >
              {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
      {list.length === 0 && (
        <Body1 className="no-matching">
          No matching Admins. Consider changing your search criteria.
        </Body1>
      )}
    </Card>
  );
};
