import { useState, useContext } from "react";
import { authentication } from "@microsoft/teams-js";
import { TeamsFxContext } from "../Context";
import useAxios from "./useAxios";
import config from "./config";

export function useGraphApiCall() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { axiosInstance: axiosPublicInstance } = useAxios(config.publicCloudEndpoint, false);

  const makeCall = async ({
    url,
    method = "post",
    scopes = ["User.Read", "User.ReadBasic.All", "Directory.Read.All"],
    payload = {},
    onSuccess,
    onError,
    ssoToken = null,
  }) => {
    setIsLoading(true);
    setError(null);

    try {
      const sso_token =
        ssoToken ||
        (await authentication.getAuthToken({
          silent: true,
        }));

      try {
        const { data: response } = await axiosPublicInstance({
          method,
          url,
          data: payload,
          headers: {
            authorization: sso_token,
          },
        });

        if (onSuccess) onSuccess(response, sso_token);
        return { response, token: sso_token };
      } catch (apiError) {
        if (apiError?.response?.status === 401 || apiError?.response?.status === 403) {
          await teamsUserCredential.login(scopes);
          const new_sso_token = await authentication.getAuthToken();

          const { data: retryResponse } = await axiosPublicInstance({
            method,
            url,
            data: payload,
            headers: {
              authorization: new_sso_token,
            },
          });

          if (onSuccess) onSuccess(retryResponse, new_sso_token);
          return { response: retryResponse, token: new_sso_token };
        }

        throw apiError;
      }
    } catch (error) {
      console.log("Error general:", error);
      setError(error);
      if (onError) onError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { makeCall, isLoading, error };
}
