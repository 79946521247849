import { useEffect } from "react";
import { Button, Spinner, Field } from "@fluentui/react-components";
import { Payment20Regular } from "@fluentui/react-icons";
import config from "../../../../lib/config";
import useAxios from "../../../../lib/useAxios";
import { useConfigWebSocket } from "../../../../lib/useConfigWebSocket";
import {
  getWSConfigClientId,
  getTenantIdFromMstToken,
  getTeamsAuthenticatedUser,
} from "../../../../lib/common";

export const PaymentMethod = ({
  ssoToken,
  totalTechnicians,
  cardPreview,
  loading,
  updateCardPreview,
}) => {
  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, false);

  const clientId = getWSConfigClientId(ssoToken);
  // Subscribe to clientId queue
  const { lastMessage } = useConfigWebSocket(
    `${config.hubEndpoint.replace("https", "wss")}/ws/connect`,
    clientId
  );

  useEffect(() => {
    if (lastMessage && lastMessage.type === "stripe_back") {
      if (lastMessage.registered) {
        updateCardPreview(
          {
            card4: lastMessage.card_last4,
            brand: lastMessage.card_brand,
          },
          false
        );
      } else {
        updateCardPreview(undefined, false);
      }
    }
  }, [lastMessage]);

  const setUpPaymentMethod = async () => {
    try {
      updateCardPreview(undefined, true);
      const adminEmail = getTeamsAuthenticatedUser(ssoToken);
      const tenantId = getTenantIdFromMstToken(ssoToken);
      const { data } = await axiosHubInstance.post(
        `/billing/setup/session?customer_email=${adminEmail}&tenant_id=${tenantId}`
      );
      window.open(data.url, "_blank");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="payment-method payment-method-container">
      <div className="summary-container">
        <div className="payment-method-summary">
          <b>A payment method is required</b>
          <p>
            Your organization has {totalTechnicians} technician users registered, exceeding the free
            tier limit. Please set up your payment method to complete registration.
          </p>
          {cardPreview ? (
            <Field
              label="Selected payment method:"
              validationState="success"
              validationMessage={cardPreview.brand.toUpperCase()}
            >
              <span>💳 •••• {cardPreview.card4}</span>
            </Field>
          ) : (
            <Button
              appearance="primary"
              icon={loading ? <Spinner size="extra-tiny" /> : <Payment20Regular />}
              onClick={setUpPaymentMethod}
            >
              Set Up Payment Method
            </Button>
          )}
          <div style={{ paddingTop: "1em" }}>
            <span className="info-description">
              No charges will be made until the 30-day period ends.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
