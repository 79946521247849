import { useState, useEffect, useCallback, useRef } from "react";

const RECONNECT_INTERVAL = 5000; // Intenta reconectar cada 5 segundos
const MAX_RECONNECT_ATTEMPTS = 5; // Número máximo de intentos de reconexión

export function useConfigWebSocket(url, clientId) {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [errorState, setErrorState] = useState(null);

  const reconnectAttempts = useRef(0);
  const reconnectTimeoutId = useRef(null);

  const connect = useCallback(() => {
    const newSocket = new WebSocket(url);

    newSocket.onopen = () => {
      if (!clientId) {
        setErrorState("No client id included");
        newSocket.close();
        return;
      }
      newSocket.send(JSON.stringify({ type: "auth", client_id: clientId }));
      reconnectAttempts.current = 0; // Resetea los intentos de reconexión
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "auth" && data.status === "success") {
        setIsConnected(true);
        setErrorState(null);
      } else if (data.type === "stripe_back") {
        setLastMessage(data);
      } else if (data.type === "heartbeat") {
        newSocket.send(JSON.stringify({ type: "heartbeat_ack" }));
      }
    };

    newSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setErrorState("WebSocket encountered an error");
    };

    newSocket.onclose = (event) => {
      setIsConnected(false);

      if (!event.wasClean && reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
        reconnectAttempts.current++;
        reconnectTimeoutId.current = setTimeout(() => {
          console.debug(`Attempting to reconnect... (Attempt ${reconnectAttempts.current})`);
          connect();
        }, RECONNECT_INTERVAL);
      } else if (reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS) {
        setErrorState("Max reconnection attempts reached. Please refresh the page.");
      }
    };

    setSocket(newSocket);
  }, [url]);

  useEffect(() => {
    connect();

    return () => {
      if (socket) {
        socket.close();
      }
      if (reconnectTimeoutId.current) {
        clearTimeout(reconnectTimeoutId.current);
      }
    };
  }, [connect]);

  return { socket, isConnected, lastMessage, errorState };
}
