import React, { useState } from "react";
import {
  Card,
  Caption2,
  ToggleButton,
  Button,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogActions,
  DialogContent,
  Field,
  Textarea,
} from "@fluentui/react-components";
import {
  bundleIcon,
  ThumbLike16Regular,
  ThumbLike16Filled,
  ThumbDislike16Filled,
  ThumbDislike16Regular,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import useAxios from "../../lib/useAxios";
import config from "../../lib/config";

const ThumbUpIcon = bundleIcon(ThumbLike16Filled, ThumbLike16Regular);
const ThumbDownIcon = bundleIcon(ThumbDislike16Filled, ThumbDislike16Regular);

const AIGeneratedSummary = ({ content, caseId }) => {
  const [feedbackExperience, setFeedbackExperience] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <FeedbackDialog
        isOpen={isDialogOpen}
        caseId={caseId}
        summary={content}
        experience={feedbackExperience}
        onClose={(processed) => {
          if (!processed) {
            setFeedbackExperience(undefined);
          }
          setIsDialogOpen(false);
        }}
      />
      <Card appearance="outline" size="small" className="summary-content">
        <span className="summary">{content}</span>
        <div className="footer-content">
          <span className="note">AI-generated summary may be incorrect</span>
          <ToggleButton
            className="icon"
            appearance="transparent"
            icon={<ThumbUpIcon />}
            size="small"
            checked={feedbackExperience === "positive"}
            onClick={() => {
              setFeedbackExperience("positive");
              setIsDialogOpen(true);
            }}
          />
          <ToggleButton
            className="icon"
            appearance="transparent"
            icon={<ThumbDownIcon />}
            size="small"
            checked={feedbackExperience === "negative"}
            onClick={() => {
              setFeedbackExperience("negative");
              setIsDialogOpen(true);
            }}
          />
        </div>
      </Card>
    </>
  );
};

const FeedbackDialog = ({ isOpen, caseId, summary, experience, onClose }) => {
  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, true);
  const [feedbackNotes, setFeedbackNotes] = useState();

  const sendFeedback = async () => {
    try {
      await axiosHubInstance.post("/feedback/send", {
        case_id: caseId,
        summary: summary,
        perception: experience,
        feedback: feedbackNotes,
      });
    } catch {
    } finally {
      onClose(true);
      setFeedbackNotes();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle style={{ justifyContent: "space-between", display: "flex" }}>
            <span>Feedback</span>
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => onClose(false)}
            />
          </DialogTitle>
          <DialogContent className="scrollable-content">
            <Field className="mb-1" label="Please provide details: (optional)">
              <Textarea
                value={feedbackNotes}
                maxLength={3500}
                placeholder={
                  experience === "positive"
                    ? "What was satisfying about this summary?"
                    : "What was unsatisfying about this summary?"
                }
                onChange={(event) => {
                  setFeedbackNotes(event.target.value);
                }}
              />
            </Field>
            <Caption2>
              Submitting this report will send the current summary to KnBases and store with your
              Feedback for future improvements to our models.
            </Caption2>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={sendFeedback}>
              Submit
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default AIGeneratedSummary;
