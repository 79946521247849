import { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Divider,
  Input,
  Field,
  Dropdown,
  Option,
  Subtitle1,
  Caption1,
  Toaster,
  Toast,
  ToastTitle,
  useToastController,
  useId,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  Tag,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
} from "@fluentui/react-components";
import { ShieldPersonAddRegular } from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../Context";
import config from "../../../lib/config";
import useAxios from "../../../lib/useAxios";
import { generateRandomId, areArraysEqual, arrayContainsAndExtends } from "../../../lib/common";
import { MessageGroup } from "../../../components/MessageGroup";
import { MessageDialog } from "../../../components/MessageDialog";
import { NewAdminDialog } from "./NewAdminDialog";
import { RevokePermissionsDialog } from "./RevokePermissionsDialog";
import { AdminDataList } from "./AdminDataList";

export function Organization() {
  const { organizationApiUrl, organizationName, updateOrganizationName, usingMobileDevice } =
    useContext(TeamsFxContext);
  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, false);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [adminList, setAdminList] = useState([]);
  const [toDisplayAdminList, setToDisplayAdminList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState();

  const [orgName, setOrgName] = useState(organizationName); //---Send to backend
  const [showErrorOrganizationName, setShowErrorOrganizationName] = useState(false);

  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null); //---Send to backend
  const [showErrorIndustrySelected, setShowErrorIndustrySelected] = useState(false);

  const [initialCategories, setInitialCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]); //---Send to backend

  const [isNewAdminDialogOpen, setIsNewAdminDialogOpen] = useState(false);
  const [selectedAdminToRevoke, setSelectedAdminToRevoke] = useState();
  const [reload, setReload] = useState();

  const toasterId = useId("updatedProfileMsg");
  const { dispatchToast } = useToastController(toasterId);

  useEffect(() => {
    const pullProfile = async () => {
      try {
        const { data: allIndustries } = await axiosHubInstance.get("/catalogs/industries");
        setIndustries(allIndustries);
        const orgProfileResponse = await axiosUserInstance.get("/org/profile");
        const profile = orgProfileResponse.data;
        if (profile) {
          if (profile.name !== orgName) {
            setOrgName(profile.name);
          }
          const orgIndustry = allIndustries.find(
            (industry) => industry.code === profile.industry_code
          );
          setSelectedIndustry(orgIndustry);
          // Categories
          const orgCategories = orgIndustry.categories.filter((category) =>
            profile.categories.includes(category.id)
          );
          setSelectedCategories(orgCategories);
          setInitialCategories(orgCategories);
        }
      } catch (err) {
        console.debug(err);
      }
    };

    setLoading(true);
    getAdminList();
    pullProfile();
  }, [reload]);

  useEffect(() => {
    applySearch();
  }, [searchText, adminList]);

  const saveOrgProfile = async () => {
    setIsDialogOpen(false);
    setTimeout(() => setDialogMessage(), 500);
    try {
      // Tags to remove
      const to_delete_tags_category = initialCategories
        .filter((catId) => !selectedCategories.includes(catId))
        .map((category) => category.name);
      // Tags to add
      let new_tags = [];
      const new_category_ids = selectedCategories
        .filter((selected) => !initialCategories.includes(selected))
        .map((c) => c.id);

      if (new_category_ids.length > 0) {
        const url = new URL("/catalogs/industries/tags", "http://my.com");
        new_category_ids.forEach((id) => url.searchParams.append("category_ids", id));
        const { data } = await axiosHubInstance.get(`${url.pathname}${url.search}`);
        if (data.length > 0) {
          new_tags = data;
        }
      }

      await axiosUserInstance.put("/org/profile", {
        name: orgName,
        industry_code: selectedIndustry.code,
        industry_name: selectedIndustry.name,
        categories: selectedCategories.map((c) => c.id),
        tags: {
          to_delete_tags_category,
          new_tags,
        },
      });
      updateOrganizationName(orgName);
      dispatchToast(
        <Toast>
          <ToastTitle>Your organization's profile was successfully updated.</ToastTitle>
        </Toast>,
        { intent: "success", duration: 2800, position: "top" }
      );
      setIsEditing(false);
      setTimeout(() => {
        setReload(true);
      }, 500);
    } catch (e) {
      dispatchToast(
        <Toast>
          <ToastTitle>{e.message}</ToastTitle>
        </Toast>,
        { intent: "error", duration: 2800, position: "top" }
      );
    }
  };

  const getAdminList = async () => {
    try {
      const { data } = await axiosUserInstance.get("/org/admins");
      setAdminList(data);
    } catch (e) {
      setMessages((prevMessages) => [
        {
          id: generateRandomId(8),
          intent: "error",
          title: "Oops!",
          body: `Some unexpected error has occurred and Admin list could not be obtained. ${e.message}. Please, try again later`,
          dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
        },
        ...prevMessages,
      ]);
    } finally {
      setLoading(false);
    }
  };

  const applySearch = useCallback(() => {
    let result = adminList;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.display_name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.email.toLowerCase().includes(searchText.toLowerCase()) ||
          item.sys_roles.join(",").toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setToDisplayAdminList(result);
  }, [adminList, searchText]);

  const handleIndustrySelected = (event, data) => {
    const selectedCode = data.optionValue;
    const industry = industries.find((industry) => industry.code === selectedCode);
    setSelectedIndustry(industry);
    setSelectedCategories([]);
    // Hide errors if exist
    if (showErrorIndustrySelected) {
      setShowErrorIndustrySelected(false);
    }
    if (!isEditing) setIsEditing(true);
  };

  const handleProfileSave = () => {
    if (orgName === "" || orgName === undefined) {
      setShowErrorOrganizationName(true);
      return;
    }
    const categoriesHaveNotChanged = areArraysEqual(initialCategories, selectedCategories);
    if (categoriesHaveNotChanged) {
      saveOrgProfile();
    } else {
      if (selectedCategories.length === 0) {
        setDialogMessage(
          <>
            <p>
              You have not included any incident categories, which may cause previous categories and
              their associated tags to be removed.
            </p>
            <p>
              <MessageGroup
                animate={false}
                messages={[
                  {
                    id: "1111",
                    intent: "warning",
                    title: "Attention:",
                    body: "If you couldn't find an incident category that fits your organization's needs, try selecting a different industry or contact us at contact@knbases.com to review your categories.",
                  },
                ]}
              />
            </p>
          </>
        );
        setIsDialogOpen(true);
        return;
      }
      const justIncludesNewCategories = arrayContainsAndExtends(
        initialCategories,
        selectedCategories
      );
      if (justIncludesNewCategories) {
        setDialogMessage(<p>New tags will be created for newly added categories</p>);
        setIsDialogOpen(true);
        return;
      } else {
        // categories are removed and added
        setDialogMessage(
          <div>
            <p>Updating your organization's incident categories will affect tags as follows:</p>
            <ul>
              <li>
                Tags from removed categories will be deleted, unless they're used in some incident.
              </li>
              <li>New tags will be created for newly added categories.</li>
            </ul>
            <p>Are you sure you want to proceed with these changes?</p>
          </div>
        );
        setIsDialogOpen(true);
        return;
      }
    }
  };

  return (
    <div className="app-body inline-content">
      <Toaster toasterId={toasterId} />
      <MessageDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setTimeout(() => setDialogMessage(), 500);
        }}
        title="Confirm Changes"
        message={dialogMessage}
        onConfirm={saveOrgProfile}
      />
      <div style={{ marginTop: "1em" }}>
        <MessageGroup messages={messages} />
      </div>
      <div className="setup-title-container">
        <Subtitle1>Organization</Subtitle1>
        <Caption1 style={{ color: "var(--colorNeutralForeground4)" }}>
          Set up the organization's profile
        </Caption1>
      </div>
      <div
        style={{
          display: "grid",
          justifyItems: "flex-start",
          marginBottom: "4em",
        }}
      >
        <div className="form-container">
          <Field
            label="Organization Name"
            className="form-field"
            validationMessage={showErrorOrganizationName ? "Provide an organization name" : ""}
          >
            <Input
              value={orgName}
              placeholder="Organization Name"
              onChange={(e) => {
                setOrgName(e.target.value);
                if (e.target.value !== "" && showErrorOrganizationName) {
                  setShowErrorOrganizationName(false);
                }
                if (!isEditing) setIsEditing(true);
              }}
            />
          </Field>
          <Field
            label="Industry"
            className="form-field"
            validationMessage={
              showErrorIndustrySelected ? "Select the industry related to your organization" : ""
            }
          >
            <Dropdown
              placeholder="Select an industry"
              onOptionSelect={handleIndustrySelected}
              selectedOptions={selectedIndustry ? [selectedIndustry?.code] : []}
              value={selectedIndustry?.name || ""}
            >
              {industries.map((industry) => (
                <Option key={industry.code} value={industry.code}>
                  {industry.name}
                </Option>
              ))}
            </Dropdown>
          </Field>
          <Field
            label="Incident Categories"
            className="form-field"
            validationMessage={
              showErrorIndustrySelected ? "Select the industry related to your organization" : ""
            }
          >
            <TagPicker
              onOptionSelect={(e, data) => {
                if (!isEditing) setIsEditing(true);
                setSelectedCategories(data.selectedOptions);
              }}
              selectedOptions={selectedCategories}
            >
              <TagPickerControl>
                <TagPickerGroup>
                  {selectedCategories.map((category) => (
                    <Tag key={category.id} shape="circular" value={category}>
                      {category.name}
                    </Tag>
                  ))}
                </TagPickerGroup>
                <TagPickerInput
                  aria-label="Select Categories"
                  placeholder={selectedCategories.length > 0 ? "" : "Select Categories"}
                />
              </TagPickerControl>
              <TagPickerList>
                {selectedIndustry &&
                selectedIndustry.categories.filter(
                  (option) => !selectedCategories.some((category) => category.id === option.id)
                ).length > 0
                  ? selectedIndustry &&
                    selectedIndustry.categories
                      .filter(
                        (option) =>
                          !selectedCategories.some((category) => category.id === option.id)
                      )
                      .map((category) => (
                        <TagPickerOption value={category} key={category.id}>
                          {category.name}
                          {/* category.description */}
                        </TagPickerOption>
                      ))
                  : "No more categories available"}
              </TagPickerList>
            </TagPicker>
          </Field>
          <div>
            <Button
              appearance="primary"
              disabled={!isEditing}
              style={{ marginTop: "1em" }}
              onClick={handleProfileSave}
            >
              Save
            </Button>
          </div>
        </div>

        <Divider style={{ paddingTop: "2em", paddingBottom: "1em" }} />
        <div className="setup-title-container">
          <Subtitle1>Resolve admins</Subtitle1>
          <Caption1 style={{ color: "var(--colorNeutralForeground4)" }}>
            Users with permission to manage Teams, Tags, Incidents, etc...
          </Caption1>
        </div>
        <NewAdminDialog
          isOpen={isNewAdminDialogOpen}
          toBeExcluded={toDisplayAdminList}
          onClose={(refresh) => {
            setIsNewAdminDialogOpen(false);
            if (refresh) getAdminList();
          }}
        />
        <RevokePermissionsDialog
          isOpen={Boolean(selectedAdminToRevoke)}
          onClose={(refresh) => {
            setSelectedAdminToRevoke();
            if (refresh) getAdminList();
          }}
          adminToRevoke={selectedAdminToRevoke}
        />
        <AdminDataList
          list={toDisplayAdminList}
          compactView={usingMobileDevice}
          onRevokeAdmin={setSelectedAdminToRevoke}
          onSearch={(text) => setSearchText(text)}
          actionComponent={
            <Button
              icon={<ShieldPersonAddRegular />}
              appearance="primary"
              onClick={() => setIsNewAdminDialogOpen(true)}
            >
              Add new Admin
            </Button>
          }
        />
      </div>
    </div>
  );
}
