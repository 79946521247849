import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
} from "@fluentui/react-components";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId } from "../../../../lib/common";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";

export const RevokePermissionsDialog = ({ isOpen, onClose, adminToRevoke }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [messages, setMessages] = useState([]);
  const [adminName, setAdminName] = useState();

  useEffect(() => {
    if (isOpen) {
      setAdminName(adminToRevoke?.display_name);
    } else {
      setTimeout(() => {
        setAdminName("");
        setMessages([]);
      }, 500);
    }
  }, [adminToRevoke]);

  const handleRevokeAction = async () => {
    try {
      await axiosUserInstance.put(`/org/admins/revoke/${adminToRevoke?.email}`);
      onClose(true);
    } catch (e) {
      let bodyMsg;
      if (e.response?.status === 304) {
        bodyMsg =
          "Permission could not be revoked, at least one owner is required in the organization";
      } else {
        bodyMsg = `Some unexpected error has occurred and permissions could not be revoked. ${e.message}. Please, try again later`;
      }

      if (!messages.some((m) => m.body === bodyMsg)) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: bodyMsg,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <MessageGroup messages={messages} />
        <DialogBody>
          <DialogTitle>Revoke admin permissions</DialogTitle>
          <DialogContent>
            <p>The administrator permissions of the user "{adminName}" will be revoked.</p>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={handleRevokeAction}>
              Revoke
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
