import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  RadioGroup,
  Radio,
  Field,
  Textarea,
} from "@fluentui/react-components";
import { MessageGroup } from "../../MessageGroup";
import { generateRandomId } from "../../../lib/common";
import { TeamsFxContext } from "../../../Context";
import useAxios from "../../../lib/useAxios";

export const CloseDialog = ({ isOpen, incident, onClose }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [messages, setMessages] = useState([]);
  const [dialogState, setDialogState] = useState({
    selectedCloseOption: null,
    closingNotes: null,
  });

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setMessages([]);
        setDialogState({
          selectedCloseOption: null,
          closingNotes: null,
        });
      }, 900);
    }
  }, [isOpen]);

  const handleCloseAction = async () => {
    try {
      const { data: closeResponse } = await axiosUserInstance.put("/incidents/status", {
        case_id: incident.case_id,
        status: dialogState.selectedCloseOption,
        notes: dialogState.closingNotes,
      });
      // Notify to the new technician assigned
      const action = {
        name: "close",
        notifications: closeResponse.notify.map((recipient) => ({
          recipient,
          title: `${
            dialogState.selectedCloseOption === "Completed"
              ? "✅ {actor} closed"
              : "🚫 {actor} cancelled"
          } the ${incident.classification}`,
          preview: incident.subject,
        })),
      };
      onClose(true, action);
    } catch (e) {
      const errorMessage = e.response?.data?.detail || e.message || "An unexpected error occurred";
      setMessages((prevMessages) => [
        {
          id: generateRandomId(8),
          intent: "error",
          title: "Closing Failed",
          body: `Unable to close the incident. ${errorMessage}. Please try again later.`,
          dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
        },
        ...prevMessages,
      ]);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <MessageGroup messages={messages} />
        <DialogBody>
          <DialogTitle>Close {incident.classification}</DialogTitle>
          <DialogContent>
            <p>Please select the reason for clossing the {incident.classification}:</p>
            <RadioGroup
              className="mb-1"
              value={dialogState.selectedCloseOption}
              onChange={(_, data) =>
                setDialogState((prevState) => ({
                  ...prevState,
                  selectedCloseOption: data.value,
                }))
              }
            >
              <Radio
                value="Canceled"
                label={
                  <span>
                    <b>Cancel</b>, the {incident.classification} was <u>created by mistake</u>
                  </span>
                }
              />
              <Radio
                value="Completed"
                label={
                  <span>
                    <b>Close</b>, the {incident.classification} was satisfactorily <u>resolved</u>
                  </span>
                }
              />
            </RadioGroup>
            {dialogState.selectedCloseOption == "Completed" && (
              <Field className="mb-1" label="Closing notes">
                <Textarea
                  value={dialogState.closingNotes}
                  maxLength={8000}
                  placeholder="Resolution notes for this case (optional)"
                  onChange={(event) => {
                    setDialogState((prevState) => ({
                      ...prevState,
                      closingNotes: event.target.value,
                    }));
                  }}
                />
              </Field>
            )}
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button
              disabled={!dialogState.selectedCloseOption}
              appearance="primary"
              onClick={handleCloseAction}
            >
              Close
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
