import { useState, useContext, useEffect, Fragment } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Button,
  Title3,
  Subtitle2,
  Caption1,
  Body1,
  Badge,
  TabList,
  Tab,
  Card,
  CardHeader,
  Persona,
  ProgressBar,
  Tag,
  Tooltip,
} from "@fluentui/react-components";
import { Dismiss24Regular, CircleFilled } from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../Context";
import { authentication } from "@microsoft/teams-js";
import TagContainer from "../../../components/TagContainer/TagContainer";
import AIGeneratedSummary from "../../../components/AIGeneratedSummary";
import { AttachmentList } from "../../../components/AttachmentList";
import Timeline from "../../../components/Timeline";
import useAxios from "../../../lib/useAxios";
import NewComment from "../../../components/NewComment";
import CommentReadOnly from "../../../components/CommentReadOnly";
import { RichEditorReadOnly } from "../../../components/RichEditorReadOnly";
import { MessageGroup } from "../../../components/MessageGroup";
import { getHumanDate, getFullDateTime, getAuthName } from "../../../lib/common";

export const MyPastCaseDetails = ({ isOpen, selectedCase, onClose }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);

  const [selectedTab, setSelectedTab] = useState("comments");
  const [loadingData, setLoadingData] = useState(true);
  const [comments, setComments] = useState([]);
  const [history, setHistory] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [errorOnMessage, setErrorOnMessage] = useState();

  const fetchCaseDetails = async () => {
    setLoadingData(true);
    try {
      const { data } = await axiosInstance.get(`/incidents/details/${selectedCase.case_id}`);
      if (data) {
        setComments(data.comments);
        setHistory(data.history);
        setAttachments(data.attachments);
      }
    } catch (e) {
      console.debug(e);
    } finally {
      setLoadingData(false);
    }
  };

  const sendNotificationToUser = async (recipient_ms_id, title, preview_text) => {
    try {
      const sso_token = await authentication.getAuthToken();
      await axiosInstance.post("/msteams/notify", {
        sso_token,
        recipient_ms_id,
        title,
        preview_text,
      });
    } catch (e) {
      console.debug(e);
    }
  };

  useEffect(() => {
    if (selectedTab !== "comments") setSelectedTab("comments");
    fetchCaseDetails();
  }, [selectedCase]);

  const addCommentToList = (jsonContent) => {
    const newComment = {
      by_user_name: getAuthName(),
      comment_date: new Date().toISOString(),
      raw_comment: jsonContent,
      isLoading: true,
    };
    setComments((prevComments) => [...prevComments, newComment]);
  };

  const updateCommentStatus = (processed, details) => {
    fetchCaseDetails();
    if (!processed) {
      setErrorOnMessage({
        id: "500",
        intent: "error",
        title: "Oops!",
        body: `Something went wrong sending the comment: ${details}`,
        dispatchAction: () => setErrorOnMessage(undefined),
      });
      setTimeout(() => {
        setErrorOnMessage(undefined);
      }, 10000);
    } else {
      // Send a notification to technician assigned
      if (selectedCase.assigned_msid) {
        const prefix = ["Completed", "Canceled"].includes(selectedCase.status) ? `[Closed] ` : "";
        sendNotificationToUser(
          selectedCase.assigned_msid,
          `💬 ${prefix}#${selectedCase.case_id} · ${selectedCase.subject}`,
          details
        );
      }
    }
  };

  const createdDatetime = getFullDateTime(selectedCase.created_at, true);

  return (
    <Drawer
      open={isOpen}
      type="overlay"
      modalType="modal"
      position="end"
      size="medium"
      className="incident-details"
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={onClose}
            />
          }
        >
          <Subtitle2 sx={{ marginLeft: "20px" }}>
            Incident details <Badge appearance="ghost">#{selectedCase.case_id}</Badge>
          </Subtitle2>
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody style={{ maxWidth: "700" }} className="scrollable-content">
        <Title3>{selectedCase.subject}</Title3>
        <div className="flex-row">
          <div style={{ padding: "20px 0px 25px 0px" }}>
            <TagContainer tags={selectedCase.tags} />
          </div>
        </div>
        {selectedCase.summary && selectedCase.summary.length > 0 && (
          <AIGeneratedSummary content={selectedCase.summary} caseId={selectedCase.case_id} />
        )}

        {/* Details status */}
        <div className="items-detail">
          <div className="item">
            <Caption1 style={{ marginBottom: "1em" }}>Assigned to</Caption1>
            <Persona
              size="medium"
              name={selectedCase.assigned_name || "Unassigned"}
              avatar={{ color: "colorful" }}
              secondaryText={selectedCase.team_name || ""}
            />
          </div>

          {selectedCase.status && (
            <div className="item">
              <Caption1 style={{ marginBottom: "1em" }}>Status</Caption1>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tag
                  shape="circular"
                  size="small"
                  appearance="brand"
                  className={`tag-status-${selectedCase.status}`}
                >
                  {selectedCase.status}
                </Tag>
              </div>
            </div>
          )}

          {selectedCase.priority && (
            <div className="item">
              <Caption1 style={{ marginBottom: "1em" }}>Priority</Caption1>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CircleFilled className={`dot-priority dot-priority-${selectedCase.priority}`} />
                <Caption1>{selectedCase.priority}</Caption1>
              </div>
            </div>
          )}

          <div className="item">
            <Caption1 style={{ marginBottom: "1em" }}>Created at</Caption1>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Caption1 className="incident-date">
                {createdDatetime[0]}
                <br />
                {createdDatetime[1]}
              </Caption1>
            </div>
          </div>
        </div>

        <div className="tabList">
          <TabList
            selectedValue={selectedTab}
            onTabSelect={(_, data) => setSelectedTab(data.value)}
          >
            <Tab id="Description" value="comments">
              Description
            </Tab>
            <Tab id="History" value="history">
              History
            </Tab>
          </TabList>
          <div>
            {selectedTab === "comments" && (
              <div>
                <Card style={{ marginTop: "2em", maxWidth: "700" }}>
                  <CardHeader
                    image={
                      <Persona
                        size="medium"
                        name={selectedCase.requester_name}
                        primaryText=""
                        avatar={{ color: "colorful" }}
                      />
                    }
                    header={
                      <Body1>
                        <b>{selectedCase.requester_name}</b>
                      </Body1>
                    }
                    description={
                      <Caption1>
                        {"Requested"} ·{" "}
                        <Tooltip
                          content={getFullDateTime(selectedCase.created_at)}
                          withArrow={true}
                          relationship="label"
                        >
                          <span>{getHumanDate(selectedCase.created_at)}</span>
                        </Tooltip>
                      </Caption1>
                    }
                  />
                  <RichEditorReadOnly initialContent={JSON.stringify(selectedCase.raw_content)} />
                  <AttachmentList list={attachments.filter((a) => a.commentId === null)} />
                </Card>

                {/* participans -> {comments.length > 0 && (
                        <div style={{ float: "right" }}>
                          {comments.length > 0 &&
                            comments.map((comment, index) => (
                              <AvatarGroup key={index} layout="stack" size={20}>
                                <AvatarGroupItem
                                  name={comment.by_user_name}
                                  key={comment.by_user_name}
                                />
                              </AvatarGroup>
                            ))}
                        </div>
                      )} */}
                {comments.length > 0 &&
                  comments.map((comment, index) => (
                    <Fragment key={index}>
                      <Card
                        key={index}
                        appearance="filled"
                        size="small"
                        style={{ maxWidth: "700" }}
                      >
                        <CommentReadOnly
                          comment={comment}
                          attachments={attachments.filter(
                            (a) => a.commentId === comment.comment_id
                          )}
                          isLoading={comment.isLoading || false}
                        />
                        {errorOnMessage && index === comments.length - 1 && (
                          <div style={{ paddingInline: 25 }}>
                            <MessageGroup messages={[errorOnMessage]} />
                          </div>
                        )}
                      </Card>
                    </Fragment>
                  ))}

                <Card appearance="filled" size="small" style={{ maxWidth: "700" }}>
                  <NewComment
                    case_id={selectedCase.case_id}
                    onCommentSent={addCommentToList}
                    onCommentProcessed={updateCommentStatus}
                  />
                </Card>
              </div>
            )}
            {selectedTab === "history" && (
              <>
                {loadingData ? (
                  <ProgressBar />
                ) : history.length > 0 ? (
                  <div style={{ paddingTop: 30 }}>
                    <Timeline history={history} />
                  </div>
                ) : (
                  <p style={{ textAlign: "center" }}>No historical elements</p>
                )}
              </>
            )}
          </div>
        </div>
      </DrawerBody>
    </Drawer>
  );
};
