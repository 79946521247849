import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Dropdown,
  Option,
  Button,
  RadioGroup,
  Radio,
} from "@fluentui/react-components";
import { MessageGroup } from "../../MessageGroup";
import { generateRandomId } from "../../../lib/common";
import { TeamsFxContext } from "../../../Context";
import useAxios from "../../../lib/useAxios";

const REASON_GROUPS = {
  TEAM_CHANGE: "reasonsForTeamChange",
  TEAM_CHANGE_FROM_UNASSIGNED: "reasonsForTeamChangeUnassigned",
  TEAMMATE_CHANGE: "reasonsForTeammateChange",
  TEAMMATE_CHANGE_FROM_UNASSIGNED: "reasonsForTeammateChangeUnassigned",
  TEAMMATE_AND_TEAM_CHANGE: "reasonsForTeammateAndTeamChange",
};

const reasonsForChange = {
  [REASON_GROUPS.TEAM_CHANGE]: [
    "[issue] initially assigned to incorrect team",
    '[issue] priority changed - Requires "[newTeam]" team',
    'Related case being handled by "[newTeam]" team',
    '"[previousTeam]" team currently at full workload capacity',
  ],
  [REASON_GROUPS.TEAM_CHANGE_FROM_UNASSIGNED]: [
    'The "[newTeam]" team is the best option now to handle the case.',
    '[issue] priority changed - Requires "[newTeam]" team',
    'Related case being handled by "[newTeam]" team',
  ],
  [REASON_GROUPS.TEAMMATE_CHANGE]: [
    '"[currentTechnician]" currently at full workload capacity',
    'Specific expertise required - Reassign to "[newTechnician]"',
    '"[currentTechnician]" temporarily unavailable',
  ],
  [REASON_GROUPS.TEAMMATE_CHANGE_FROM_UNASSIGNED]: [
    'Specific expertise required - assign to "[newTechnician]"',
    'There are no other technicians available except "[newTechnician]".',
  ],
  [REASON_GROUPS.TEAMMATE_AND_TEAM_CHANGE]: [
    "[issue] initially assigned to incorrect team",
    'Escalation needed - Reassign to "[newTechnician]" ("[newTeam]" specialist)',
    'Related case being handled by "[newTechnician]" ("[newTeam]" specialist)',
    '"[currentTechnician]" temporarily unavailable',
  ],
};

const determineInitialReasonForChange = (toAssignTeam, toAssignTechnician, incident) => {
  if (toAssignTechnician && toAssignTechnician.ms_id !== incident.assigned_msid) {
    if (!toAssignTeam || toAssignTeam.id === incident.team_id) {
      if (incident.assigned_email === null) {
        return REASON_GROUPS.TEAMMATE_CHANGE_FROM_UNASSIGNED;
      } else {
        return REASON_GROUPS.TEAMMATE_CHANGE;
      }
    }
    return REASON_GROUPS.TEAMMATE_AND_TEAM_CHANGE;
  }
  if (toAssignTeam && incident.team_id === null) {
    return REASON_GROUPS.TEAM_CHANGE_FROM_UNASSIGNED;
  }
  return REASON_GROUPS.TEAM_CHANGE; // Default case
};

export const ReassignDialog = ({ isOpen, incident, toAssignTeam, toAssignTechnician, onClose }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [messages, setMessages] = useState([]);
  const [dialogState, setDialogState] = useState({
    selectedTeamOption: null,
    confirmTeam: false,
    reassignOption: null,
    reasonForChangeGroup: REASON_GROUPS.TEAM_CHANGE,
  });

  useEffect(() => {
    if (isOpen) {
      setDialogState({
        selectedTeamOption: toAssignTeam,
        confirmTeam: false,
        reassignOption: null,
        reasonForChangeGroup: determineInitialReasonForChange(
          toAssignTeam,
          toAssignTechnician,
          incident
        ),
      });
    } else {
      setTimeout(() => {
        setMessages([]);
        setDialogState({
          selectedTeamOption: null,
          confirmTeam: false,
          reassignOption: null,
          reasonForChangeGroup: REASON_GROUPS.TEAM_CHANGE,
        });
      }, 900);
    }
  }, [isOpen]);

  const handleReassignAction = async () => {
    try {
      const { data: reassignResponse } = await axiosUserInstance.post("/incidents/reassign", {
        case_id: incident.case_id,
        to_assign_team_id: dialogState.selectedTeamOption?.id || toAssignTechnician.teams.at(0).id, // Assign the select team or the technician's first (only) team
        to_assign_technician_id: toAssignTechnician?.userid,
        reason: dialogState.reassignOption,
      });
      // Notify to the new technician assigned
      const action = {
        name: "reassign",
        notifications: [
          {
            recipient: reassignResponse.technician_ms_id,
            title: reassignResponse.is_manual_assignation
              ? `{actor} reassigned you ${
                  incident.classification === "incident" ? "an incident" : "a request"
                }`
              : `${
                  incident.classification === "incident" ? "An incident" : "A request"
                } has been reassigned to you`,
            preview: incident.subject,
          },
        ],
      };
      onClose(true, action);
    } catch (e) {
      if (e.response?.status === 409) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Reassignment Failed",
            body:
              e.response?.data?.detail ||
              e.message ||
              "Unable to reassign this incident, no technician available to assign in the team.",
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      } else {
        const errorMessage =
          e.response?.data?.detail || e.message || "An unexpected error occurred";
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Reassignment Failed",
            body: `Unable to reassign the incident. ${errorMessage}. Please try again later.`,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    }
  };

  const handleSelectTeamAction = () => {
    setDialogState((prevState) => {
      const newSelectedTeam = prevState.selectedTeamOption || toAssignTeam;
      const newReasonForChangeGroup =
        newSelectedTeam.id !== incident.team_id
          ? REASON_GROUPS.TEAMMATE_AND_TEAM_CHANGE
          : REASON_GROUPS.TEAMMATE_CHANGE;

      return {
        ...prevState,
        confirmTeam: true,
        reasonForChangeGroup: newReasonForChangeGroup,
        selectedTeamOption: newSelectedTeam,
      };
    });
  };

  const replaceplaceholders = useCallback(
    (reason) => {
      return reason
        .replace("[previousTeam]", incident.team_name)
        .replace("[newTeam]", dialogState.selectedTeamOption?.name || "")
        .replace("[currentTechnician]", incident.assigned_name)
        .replace("[newTechnician]", toAssignTechnician?.display_name || "")
        .replace("[issue]", incident.classification);
    },
    [incident.team_name, dialogState.selectedTeamOption, incident.assigned_name, toAssignTechnician]
  );

  const shouldShowTeamSelection = useMemo(() => {
    return (
      !toAssignTeam &&
      toAssignTechnician &&
      toAssignTechnician.teams.length > 1 &&
      !dialogState.confirmTeam
    );
  }, [toAssignTeam, toAssignTechnician, dialogState.confirmTeam]);

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <MessageGroup messages={messages} />
        <DialogBody>
          <DialogTitle>Reassign {incident.classification}</DialogTitle>
          {shouldShowTeamSelection ? (
            <DialogContent>
              <p>
                <b>{toAssignTechnician.display_name}</b> belongs to multiple technical teams. Please
                select under which team this incident should be addressed:
              </p>
              <Dropdown
                placeholder={`Select ${toAssignTechnician.display_name}'s team`}
                onOptionSelect={(event, data) => {
                  setDialogState((prevState) => ({
                    ...prevState,
                    selectedTeamOption: data.optionValue,
                  }));
                }}
              >
                {toAssignTechnician.teams.map((team) => (
                  <Option key={team.id} value={team}>
                    {team.name}
                  </Option>
                ))}
              </Dropdown>
            </DialogContent>
          ) : (
            <DialogContent>
              <p>Please select the reason for reassigning the {incident.classification}:</p>
              {/* <u>{dialogState.reasonForChangeGroup}</u> */}
              <RadioGroup
                value={dialogState.reassignOption}
                onChange={(_, data) =>
                  setDialogState((prevState) => ({
                    ...prevState,
                    reassignOption: data.value,
                  }))
                }
              >
                {reasonsForChange[dialogState.reasonForChangeGroup].map((reason) => (
                  <Radio
                    key={reason}
                    value={replaceplaceholders(reason)}
                    label={<span className="first-letter">{replaceplaceholders(reason)}</span>}
                  />
                ))}
              </RadioGroup>
            </DialogContent>
          )}
          <DialogActions>
            <Button appearance="secondary" onClick={() => onClose(false)}>
              Cancel
            </Button>
            {shouldShowTeamSelection ? (
              <Button
                disabled={!dialogState.selectedTeamOption}
                appearance="primary"
                onClick={handleSelectTeamAction}
              >
                Continue
              </Button>
            ) : (
              <Button
                disabled={!dialogState.reassignOption}
                appearance="primary"
                onClick={handleReassignAction}
              >
                Confirm
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
