import { createContext } from "react";

export const TeamsFxContext = createContext({
  themeString: "",
  organizationName: "",
  updateOrganizationName: () => {},
  updateOrganizationStatus: () => {},
  updateActivePlan: () => {},
  teamsUserCredential: undefined,
  organizationApiUrl: undefined,
  organizationStatus: undefined,
  hasActivePlan: undefined,
  freeTechnicians: undefined,
  usingMobileDevice: undefined,
});
