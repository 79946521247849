import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Checkbox,
  Combobox,
  Option,
  OptionGroup,
} from "@fluentui/react-components";
import { PeopleCommunity20Regular } from "@fluentui/react-icons";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId } from "../../../../lib/common";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";

export const TeamDeleteDialog = ({ isOpen, onClose, toDeleteTeam }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [messages, setMessages] = useState([]);
  const [teamName, setTeamName] = useState();
  const [availableTeams, setAvailableTeams] = useState([]);
  const [confirmChecked, setConfirmChecked] = useState(false);

  const [hasSomeAssignedIncidents, setHasSomeAssignedIncidents] = useState();
  const [isLastTeam, setIsLastTeam] = useState();
  const [reassignOption, setReassignOption] = useState();

  useEffect(() => {
    const fetchTeamsNames = async () => {
      try {
        const { data } = await axiosUserInstance.get("/org/teams/names");
        if (data) {
          setAvailableTeams(data);
        }
      } catch (e) {
        console.debug(e);
      }
    };
    const checkForAssignedIncidents = async () => {
      try {
        const { data } = await axiosUserInstance.get(`/org/teams/${toDeleteTeam?.id}/any_incident`);
        setHasSomeAssignedIncidents(data.have_any_incident);
        setIsLastTeam(!data.have_more_teams);
        if (data.have_any_incident && data.have_more_teams) {
          fetchTeamsNames();
        }
      } catch (e) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: `Some unexpected error has occurred and Team's data could not be obtained. ${e.message}. Please, try again later`,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    };

    if (isOpen) {
      setTeamName(toDeleteTeam?.name);
      checkForAssignedIncidents();
    } else {
      setTimeout(() => {
        setTeamName("");
        setConfirmChecked();
        setHasSomeAssignedIncidents();
        setIsLastTeam();
        setReassignOption();
        setMessages([]);
      }, 500);
    }
  }, [toDeleteTeam]);

  const handleChange = (ev, data) => {
    setConfirmChecked(Boolean(data.checked));
  };

  const handleDeleteAction = async () => {
    try {
      await axiosUserInstance.delete(
        `/org/teams/${toDeleteTeam?.id}?reassingTo=${reassignOption || -1}`
      );
      onClose(true);
    } catch (e) {
      const bodyMsg = `Some unexpected error has occurred and Team could not be deleted. ${e.message}. Please, try again later`;
      if (!messages.some((m) => m.body === bodyMsg)) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: bodyMsg,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <MessageGroup messages={messages} />
        <DialogBody>
          <DialogTitle>Delete team "{teamName}"?</DialogTitle>
          <DialogContent>
            <p>
              You're about to delete the Team "{teamName}".{" "}
              {/* Es el ultimo equipo y no tiene incidentes asignados */}
              {isLastTeam &&
                !hasSomeAssignedIncidents &&
                "Please note that this is the latest Team, and without teams it won't be possible to automatically assign new incidents to technicians. "}
              {/* Es el ultimo equipo y tiene incidentes asignados */}
              {isLastTeam &&
                hasSomeAssignedIncidents &&
                "Please note that this is the latest Team, and without teams it won't be possible to automatically assign new incidents to technicians. Incidents under this team will be left unassigned. "}
              {/* No es el ultimo equipo y tiene incidentes asignados */}
              {!isLastTeam &&
                hasSomeAssignedIncidents &&
                `This Team has some incidents assigned to it. Please select where you would like to reassign them:`}
              {/* No es el ultimo equipo y no tiene incidentes asignados */}
              {!isLastTeam &&
                !hasSomeAssignedIncidents &&
                "No active incidents were found assigned to this team. Please confirm this action below."}
            </p>
            {!isLastTeam && hasSomeAssignedIncidents ? (
              <Combobox
                readOnly
                onOptionSelect={(e, data) => {
                  setReassignOption(data.optionValue);
                  if (!confirmChecked) setConfirmChecked(true);
                }}
                placeholder="Select a team"
              >
                <Option value="-1">Leave unassigned</Option>
                <OptionGroup label="Technician Teams">
                  {availableTeams.map((team) => (
                    <Option key={team.id} value={team.id} disabled={team.id === toDeleteTeam?.id}>
                      <PeopleCommunity20Regular />
                      {team.name}
                    </Option>
                  ))}
                </OptionGroup>
              </Combobox>
            ) : (
              <Checkbox
                checked={confirmChecked}
                onChange={handleChange}
                label="Yes, delete this Team"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button disabled={!confirmChecked} appearance="primary" onClick={handleDeleteAction}>
              Delete
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
