import { useRef, useMemo } from "react";
import {
  PeopleCommunity20Regular,
  New20Filled,
  MoreHorizontalFilled,
  bundleIcon,
  Edit20Filled,
  Edit20Regular,
  Delete20Filled,
  Delete20Regular,
} from "@fluentui/react-icons";
import {
  Card,
  Tag,
  Button,
  Body1,
  Badge,
  Caption1Strong,
  Divider,
  SearchBox,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  createTableColumn,
  TableCellActions,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Tooltip,
} from "@fluentui/react-components";
import { getHumanDate } from "../../../../lib/common";

const columnSizingOptions = {
  name: {
    minWidth: 120,
    defaultWidth: 250,
  },
  profiles: {
    defaultWidth: 420,
    minWidth: 250,
    idealWidth: 420,
  },
  description: {
    defaultWidth: 100,
    minWidth: 80,
    idealWidth: 100,
  },
  created_at: {
    defaultWidth: 170,
    minWidth: 80,
    idealWidth: 170,
  },
};

const EditIcon = bundleIcon(Edit20Filled, Edit20Regular);
const DeleteIcon = bundleIcon(Delete20Filled, Delete20Regular);

const createColumns = (onEditTeam, onDeleteTeam) => [
  createTableColumn({
    columnId: "name",
    compare: (a, b) => {
      return a.name.localeCompare(b.name);
    },
    renderHeaderCell: () => {
      return "Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout
          media={
            getHumanDate(item.created_at) === "just now" ? (
              <New20Filled />
            ) : (
              <PeopleCommunity20Regular />
            )
          }
        >
          <div className="horizontal-content">
            {item.name}
            {item.teammates.length > 0 ? (
              <>
                {" / "}
                <Badge appearance="ghost">
                  {item.teammates.length} {item.teammates.length === 1 ? "member" : "members"}
                </Badge>
              </>
            ) : null}
          </div>
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.name,
  }),
  createTableColumn({
    columnId: "profiles",
    compare: (a, b) => {
      return a.profiles.length - b.profiles.length;
    },
    renderHeaderCell: () => {
      return "Profiles";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout>
          {item.profiles.map((profile) => (
            <Tag
              key={profile}
              size="small"
              appearance="brand"
              shape="circular"
              style={{ margin: "3px" }}
            >
              {profile}
            </Tag>
          ))}
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.profiles,
  }),
  createTableColumn({
    columnId: "description",
    compare: (a, b) => {
      return a.description?.localeCompare(b.description);
    },
    renderHeaderCell: () => {
      return "Description";
    },
    renderCell: (item) => {
      return (
        <Tooltip content={item.description} withArrow={true}>
          <TableCellLayout truncate>{item.description}</TableCellLayout>
        </Tooltip>
      );
    },
    sizingOptions: columnSizingOptions.description,
  }),
  createTableColumn({
    columnId: "created_at",
    compare: (a, b) => {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    },
    renderHeaderCell: () => {
      return "Created";
    },

    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {getHumanDate(item.created_at)}
          <TableCellActions>
            <Menu>
              <MenuTrigger>
                <Button appearance="subtle" aria-label="more" icon={<MoreHorizontalFilled />} />
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem icon={<EditIcon />} onClick={() => onEditTeam(item)}>
                    Edit Team
                  </MenuItem>
                  <MenuItem icon={<DeleteIcon />} onClick={() => onDeleteTeam(item)}>
                    Delete Team
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </TableCellActions>
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.created_at,
  }),
];

const createCompactColumns = (onEditTeam, onDeleteTeam) => [
  createTableColumn({
    columnId: "name",
    compare: (a, b) => {
      return a.name.localeCompare(b.name);
    },
    renderHeaderCell: () => {
      return "Team";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout
          media={
            getHumanDate(item.created_at) === "just now" ? (
              <New20Filled />
            ) : (
              <PeopleCommunity20Regular />
            )
          }
        >
          <div>
            {item.name}
            {item.teammates.length > 0 && (
              <div>
                <Caption1Strong style={{ color: "var(--colorCompoundBrandForeground1)" }}>
                  {item.teammates.length} {item.teammates.length === 1 ? "member" : "members"}
                </Caption1Strong>
              </div>
            )}
          </div>
          <TableCellActions>
            <Menu>
              <MenuTrigger>
                <Button appearance="subtle" aria-label="more" icon={<MoreHorizontalFilled />} />
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem icon={<EditIcon />} onClick={() => onEditTeam(item)}>
                    Edit Team
                  </MenuItem>
                  <MenuItem icon={<DeleteIcon />} onClick={() => onDeleteTeam(item)}>
                    Delete Team
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </TableCellActions>
        </TableCellLayout>
      );
    },
  }),
];

export const TeamsDataList = ({
  list,
  compactView,
  actionComponent,
  onEditTeam,
  onDeleteTeam,
  onSearch,
}) => {
  const refMap = useRef({});
  const columns = useMemo(() => {
    return compactView
      ? createCompactColumns(onEditTeam, onDeleteTeam)
      : createColumns(onEditTeam, onDeleteTeam);
  }, [onEditTeam, onDeleteTeam, compactView]);

  return (
    <Card>
      <div className="flex-row">
        {actionComponent}
        <SearchBox
          appearance="filled-darker"
          placeholder="Search"
          onChange={(_, data) => onSearch(data.value)}
        />
      </div>
      <Divider />
      <div style={{ overflow: "auto" }}>
        <DataGrid
          items={list}
          columns={columns}
          sortable
          data-show-checkboxes="false"
          getRowId={(item) => item.id}
          resizableColumns
          columnSizingOptions={columnSizingOptions}
          resizableColumnsOptions={{
            autoFitColumns: false,
          }}
        >
          <DataGridHeader>
            <DataGridRow
              selectionCell={{
                checkboxIndicator: { "aria-label": "Select all rows" },
              }}
            >
              {({ renderHeaderCell, columnId }, dataGrid) =>
                dataGrid.resizableColumns ? (
                  <Menu openOnContext>
                    <MenuTrigger>
                      <DataGridHeaderCell
                        /* style={{ fontWeight: "600" }} */
                        ref={(el) => (refMap.current[columnId] = el)}
                      >
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(columnId)}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ) : (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )
              }
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody>
            {({ item, rowId }) => (
              <DataGridRow
                key={rowId}
                selectionCell={{
                  checkboxIndicator: { "aria-label": "Select row" },
                }}
              >
                {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      </div>
      {list.length === 0 && (
        <Body1 className="no-matching">
          No matching Teams. Consider changing your search criteria.
        </Body1>
      )}
    </Card>
  );
};
