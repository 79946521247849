import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Field,
  Input,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  Option,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  Tag,
  Link,
  Avatar,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Textarea,
  Spinner,
} from "@fluentui/react-components";
import { CheckboxUnchecked20Regular, CheckboxChecked20Filled } from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";
import config from "../../../../lib/config";
import { useGraphApiCall } from "../../../../lib/useGraphApiCall";
import { debounce } from "lodash";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId, haveRole } from "../../../../lib/common";

export const TeamDetailsDialog = ({ isOpen, onClose, mode, selectedTeam, teams }) => {
  const { organizationApiUrl, hasActivePlan, freeTechnicians, usingMobileDevice } =
    useContext(TeamsFxContext);
  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, false);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);
  const { makeCall, isLoading } = useGraphApiCall();
  const navigate = useNavigate();

  const [locations, setLocations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [ssoToken, setSsoToken] = useState();

  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [teamNamePlaceholder, setTeamNamePlaceholder] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [industryProfiles, setIndustryProfiles] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [typing, setTyping] = useState(false);
  const [selectedTeammates, setSelectedTeammates] = useState([]);
  // Errors
  const [showErrorName, setShowErrorName] = useState(false);
  const [showErrorProfiles, setShowErrorProfiles] = useState(false);
  const [showErrorTeammates, setShowErrorTeammates] = useState(false);

  useEffect(() => {
    const getLocations = async () => {
      try {
        const { data } = await axiosUserInstance.get("/org/locations");
        setLocations(data);
      } catch (e) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: `Some unexpected error has occurred and Locations could not be obtained. ${e.message}. Please, try again later`,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    };
    const configureTeamsProfiles = async () => {
      try {
        const profileResponse = await axiosUserInstance.get("/org/profile");
        const url = new URL("/catalogs/industries/profiles", "http://my.com");
        profileResponse.data.categories.forEach((id) =>
          url.searchParams.append("category_ids", id)
        );
        const { data } = await axiosHubInstance.get(`${url.pathname}${url.search}`);
        if (data.length > 0) {
          setTeamNamePlaceholder(`e.g., ${data[0].suggestion}`);
        }
        setIndustryProfiles(data);
      } catch (e) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: `Some unexpected error has occurred and Profiles could not be obtained. ${e.message}. Please, try again later`,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    };
    //getLocations();
    configureTeamsProfiles();
  }, []);

  useEffect(() => {
    if (mode === "edit" && selectedTeam) {
      setSelectedProfiles(selectedTeam.profiles || []);
      setSelectedTeammates(
        selectedTeam.teammates.map((m) => ({
          email: m.email,
          display_name: m.display_name,
          ms_id: m.ms_id,
          job_title: m.job_title,
        })) || []
      );
      setTeamName(selectedTeam.name);
      setTeamDescription(selectedTeam.description);
    } else {
      if (!isOpen) {
        setTimeout(() => {
          setTeamName("");
          setTeamDescription("");
          setSelectedProfiles([]);
          setSelectedTeammates([]);
          setShowErrorName(false);
          setShowErrorProfiles(false);
          setShowErrorTeammates(false);
          setMessages([]);
        }, 500);
      }
    }
    // Check plan
    if (isOpen) {
      validatePlan([]);
    }
  }, [mode, selectedTeam, isOpen]);

  const getOrganizationUsers = debounce(async (contain) => {
    try {
      await makeCall({
        url: `/msteams/delegated/users?contain=${contain}`,
        method: "get",
        scopes: ["User.ReadBasic.All"],
        onSuccess: (response, token) => {
          setOrganizationUsers(response);
          if (!ssoToken) {
            setSsoToken(token);
          }
        },
        onError: (error) => {
          console.error("Error getting users:", error);
        },
        ssoToken: ssoToken,
      });
    } catch (error) {
      console.error(error);
    }
  }, 500);

  const validatePlan = (newTeamMates) => {
    if (!hasActivePlan) {
      const otherTeams = teams.filter((t) => t.id !== selectedTeam?.id);
      const existingTechnicians = [
        ...new Set(otherTeams.flatMap((t) => t.teammates.map((tm) => tm.email))),
      ];

      const allUniqueTechnicians = [
        ...new Set([...existingTechnicians, ...newTeamMates.map((tm) => tm.email)]),
      ];

      if (allUniqueTechnicians.length > freeTechnicians) {
        const isAdminOwner = haveRole("owner");
        setMessages([
          {
            id: generateRandomId(8),
            intent: "warning",
            body: (
              <>
                {isAdminOwner && !usingMobileDevice && (
                  <span>
                    You've reached the limit of free technicians ({freeTechnicians}). To add more
                    team members, please add a{" "}
                    <Link
                      className="link"
                      onClick={() => {
                        onClose(false);
                        navigate("../manage_subscription");
                      }}
                    >
                      payment method
                    </Link>{" "}
                    to continue.
                  </span>
                )}
                {isAdminOwner && usingMobileDevice && (
                  <span>
                    You've reached the limit of free technicians ({freeTechnicians}). For
                    information about our plans and capacity options, please contact our support
                    team.
                  </span>
                )}
                {!isAdminOwner && !usingMobileDevice && (
                  <span>
                    You've reached the limit of free technicians ({freeTechnicians}). To add more
                    team members, a payment method is required. Please contact the primary
                    administrator to set up a payment method.
                  </span>
                )}
                {!isAdminOwner && usingMobileDevice && (
                  <span>
                    You've reached the limit of free technicians ({freeTechnicians}). Please contact
                    the primary administrator to know more about plans and capacity options.
                  </span>
                )}
              </>
            ),
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
        ]);
        return false;
      } else if (messages.length > 0) {
        setMessages([]);
      }
    }
    return true;
  };

  const saveTeams = async () => {
    let isValidForm = true;
    if (teamName.trim().length === 0) {
      setShowErrorName(true);
      isValidForm = false;
    }
    if (selectedProfiles.length === 0) {
      setShowErrorProfiles(true);
      isValidForm = false;
    }
    if (selectedTeammates.length === 0) {
      setShowErrorTeammates(true);
      isValidForm = false;
    }

    if (!validatePlan(selectedTeammates)) {
      isValidForm = false;
    }

    if (isValidForm) {
      if (mode === "create") {
        try {
          await axiosUserInstance.post("/org/teams", {
            name: teamName,
            description: teamDescription,
            profiles: selectedProfiles,
            teammates: selectedTeammates.map((m) => ({
              display_name: m.display_name,
              email: m.email,
              ms_id: m.ms_id,
              job_title: m.jobTitle,
            })),
          });
          onClose(true);
        } catch (e) {
          const bodyMsg = `Some unexpected error has occurred and Team could not be created. ${e.message}. Please, try again later`;
          if (!messages.some((m) => m.body === bodyMsg)) {
            setMessages((prevMessages) => [
              {
                id: generateRandomId(8),
                intent: "error",
                title: "Oops!",
                body: bodyMsg,
                dispatchAction: (msgId) =>
                  setMessages((s) => s.filter((entry) => entry.id !== msgId)),
              },
              ...prevMessages,
            ]);
          }
        }
      } else if (mode === "edit") {
        try {
          await axiosUserInstance.put("/org/teams", {
            id: selectedTeam.id,
            name: teamName,
            description: teamDescription,
            profiles: selectedProfiles,
            teammates: selectedTeammates.map((m) => ({
              display_name: m.display_name,
              email: m.email,
              ms_id: m.ms_id,
              job_title: m.job_title,
            })),
          });
          onClose(true);
        } catch (e) {
          const bodyMsg = `Some unexpected error has occurred and Team could not be updated. ${e.message}. Please, try again later`;
          if (!messages.some((m) => m.body === bodyMsg)) {
            setMessages((prevMessages) => [
              {
                id: generateRandomId(8),
                intent: "error",
                title: "Oops!",
                body: bodyMsg,
                dispatchAction: (msgId) =>
                  setMessages((s) => s.filter((entry) => entry.id !== msgId)),
              },
              ...prevMessages,
            ]);
          }
        }
      }
    }
  };

  const userSearchResult = organizationUsers.filter(
    (option) => !selectedTeammates.some((teammate) => teammate.email === option.email)
  );

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{mode === "create" ? "Add new Team" : "Edit Team"}</DialogTitle>
          <DialogContent>
            <MessageGroup messages={messages} />
            <div style={{ marginBottom: "4em", display: "grid", gap: "0.5em" }}>
              <Field
                label="Name"
                required
                validationMessage={showErrorName ? "Specify a name to identify the team" : ""}
              >
                <Input
                  placeholder={teamNamePlaceholder}
                  value={teamName}
                  onChange={(event) => {
                    setTeamName(event.target.value);
                    if (showErrorName && event.target.value.trim().length > 0)
                      setShowErrorName(false);
                  }}
                />
              </Field>
              <Field
                label="Profiles"
                required
                validationMessage={showErrorProfiles ? "Select at least one profile" : ""}
              >
                <Popover
                  positioning={{
                    position: "below",
                    align: "start",
                    offset: {
                      mainAxis: 4,
                    },
                    matchTargetSize: true,
                  }}
                >
                  <PopoverTrigger disableButtonEnhancement>
                    <Input
                      readOnly
                      placeholder="Select profiles"
                      value={selectedProfiles.length > 0 ? selectedProfiles.join(", ") : ""}
                      style={{ cursor: "pointer" }}
                    />
                  </PopoverTrigger>
                  <PopoverSurface style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {industryProfiles.map((option) => (
                      <div key={option.profile_category}>
                        <span className="menu-subtitle">{option.profile_category}</span>
                        {option.profiles.map((profile) => (
                          <Option
                            key={profile}
                            className="menu-item"
                            onClick={() => {
                              const newSelection = selectedProfiles.includes(profile)
                                ? selectedProfiles.filter((p) => p !== profile)
                                : [...selectedProfiles, profile];
                              setSelectedProfiles(newSelection);
                              if (showErrorProfiles) setShowErrorProfiles(false);
                            }}
                            style={{
                              backgroundColor: selectedProfiles.includes(profile)
                                ? "var(--colorNeutralBackground1Selected)"
                                : "transparent",
                            }}
                          >
                            {selectedProfiles.includes(profile) ? (
                              <CheckboxChecked20Filled
                                style={{ color: "var(--colorNeutralStrokeAccessibleSelected)" }}
                              />
                            ) : (
                              <CheckboxUnchecked20Regular />
                            )}
                            {profile}
                          </Option>
                        ))}
                      </div>
                    ))}
                  </PopoverSurface>
                </Popover>
              </Field>
              <Field
                label="Teammates"
                required
                validationMessage={showErrorTeammates ? "Select at least one teammate" : ""}
              >
                <TagPicker
                  onOptionSelect={(e, data) => {
                    setTyping(false);
                    setSelectedTeammates(data.selectedOptions);
                    validatePlan(data.selectedOptions);
                    if (showErrorTeammates) setShowErrorTeammates(false);
                  }}
                  selectedOptions={selectedTeammates}
                >
                  <TagPickerControl expandIcon={isLoading ? <Spinner size="tiny" /> : null}>
                    <TagPickerGroup>
                      {selectedTeammates.map((teammate) => (
                        <Tag
                          key={teammate.email}
                          shape="circular"
                          media={
                            <Avatar aria-hidden name={teammate.display_name} color="colorful" />
                          }
                          value={teammate}
                        >
                          {teammate.display_name}
                        </Tag>
                      ))}
                    </TagPickerGroup>
                    <TagPickerInput
                      aria-label="Enter teammate's name"
                      placeholder={selectedTeammates.length > 0 ? "" : "Enter teammate's name"}
                      onChange={(e) => {
                        setTyping(Boolean(e.target.value));
                        getOrganizationUsers(e.target.value);
                      }}
                      onBlur={(e) => setTyping(false)}
                    />
                  </TagPickerControl>
                  <TagPickerList>
                    {userSearchResult.length > 0
                      ? userSearchResult.map((user) => (
                          <TagPickerOption
                            media={
                              <Avatar
                                shape="circular"
                                aria-hidden
                                name={user.display_name}
                                color="colorful"
                              />
                            }
                            value={user}
                            key={user.email}
                          >
                            {user.display_name}
                          </TagPickerOption>
                        ))
                      : typing
                      ? isLoading || "We couldn't find any matches"
                      : "Enter teammate's name"}
                  </TagPickerList>
                </TagPicker>
              </Field>
              <Field label="Description">
                <Textarea
                  value={teamDescription}
                  maxLength={5000}
                  onChange={(event) => {
                    setTeamDescription(event.target.value);
                  }}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={saveTeams}>
              {mode === "create" ? "Create" : "Update"}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
