import React, { useState, useEffect, useContext, useCallback, useRef, memo } from "react";
import useAxios from "../../lib/useAxios";
import { TeamsFxContext } from "../../Context";
import {
  Subtitle2Stronger,
  Toaster,
  Toast,
  ToastTitle,
  useToastController,
  useId,
} from "@fluentui/react-components";
import { useSecureWebSocket } from "../../lib/useSecureWebSocket";
import { ReportNewIncident } from "./ReportNewIncident";
import { IncidentPost } from "./IncidentPost";
import { MyPastList } from "./MyPastList";
import AnnouncementSmall from "../../components/AnnouncementSmall";
import { MyPastCaseDetails } from "./MyPastCaseDetails";

const MemoizedReportNewIncident = memo(ReportNewIncident);
const MemoizedIncidentPost = memo(IncidentPost);
const MemoizedMyPastList = memo(MyPastList);
const MemoizedMyPastCaseDetails = memo(MyPastCaseDetails);

export function RequesterScreen() {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);
  const { lastMessage } = useSecureWebSocket(
    `${organizationApiUrl.replace("https", "wss")}/ws/connect`
  );

  const [loadingData, setLoadingData] = useState(false);
  const [incidentsData, setIncidentsData] = useState({ open: [], closed: [] });
  const [selectedPastCase, setSelectedPastCase] = useState();

  const toasterId = useId("myIncidentsMessages");
  const { dispatchToast } = useToastController(toasterId);

  const hasFetchedOpenIncidentsRef = useRef(false);
  const hasFetchedClosedIncidentsRef = useRef(false);

  const fetchIncidents = useCallback(async () => {
    setLoadingData(true);
    try {
      if (!hasFetchedOpenIncidentsRef.current) {
        const { data: openData } = await axiosInstance.get("/incidents/my-requests?filter=open");
        if (openData) {
          setIncidentsData((prev) => ({ ...prev, open: openData.incidents }));
          console.debug(openData.totals);
        }
      }

      if (!hasFetchedClosedIncidentsRef.current) {
        const { data: closedData } = await axiosInstance.get(
          "/incidents/my-requests?filter=closed"
        );
        if (closedData) {
          setIncidentsData((prev) => ({ ...prev, closed: closedData.incidents }));
        }
      }
    } catch (e) {
      dispatchToast(
        <Toast>
          <ToastTitle>{e.message}</ToastTitle>
        </Toast>,
        { intent: "error", duration: 2800, position: "top" }
      );
    } finally {
      setLoadingData(false);
      hasFetchedOpenIncidentsRef.current = true;
      hasFetchedClosedIncidentsRef.current = true;
    }
  }, [axiosInstance, dispatchToast]);

  useEffect(() => {
    fetchIncidents();
  }, [fetchIncidents]);

  useEffect(() => {
    if (lastMessage) {
      const { type, data } = lastMessage;

      const updateIncidentsList = (list, updatedIncident) => {
        return list.map((incident) =>
          incident.case_id === updatedIncident.case_id
            ? { ...incident, ...updatedIncident }
            : incident
        );
      };

      switch (type) {
        case "incident_update":
          setIncidentsData((prev) => {
            let updatedOpen = prev.open;
            let updatedClosed = prev.closed;

            const currentIncident = [...prev.open, ...prev.closed].find(
              (incident) => incident.case_id === data.case_id
            );

            if (currentIncident && data.status && currentIncident.status !== data.status) {
              if (data.status === "Completed" || data.status === "Canceled") {
                // Mover el incidente de 'open' a 'closed'
                updatedOpen = prev.open.filter((incident) => incident.case_id !== data.case_id);
                updatedClosed = [...prev.closed, { ...currentIncident, ...data }];
              } else if (data.status === "Open") {
                // Mover el incidente de 'closed' a 'open'
                updatedClosed = prev.closed.filter((incident) => incident.case_id !== data.case_id);
                updatedOpen = [...prev.open, { ...currentIncident, ...data }];
              }
            } else {
              // Actualizar en la lista correspondiente si el estado no ha cambiado
              updatedOpen = updateIncidentsList(prev.open, data);
              updatedClosed = updateIncidentsList(prev.closed, data);
            }

            return {
              open: updatedOpen,
              closed: updatedClosed,
            };
          });

          // Actualizar selectedPastCase si es necesario
          if (selectedPastCase && selectedPastCase.case_id === data.case_id) {
            setSelectedPastCase((prev) => ({ ...prev, ...data }));
          }
          break;
        default:
          console.debug("Unhandled message type:", type);
      }
    }
  }, [lastMessage, selectedPastCase]);

  const handleIncidentSubmitted = useCallback(() => {
    hasFetchedOpenIncidentsRef.current = false;
    fetchIncidents();
  }, [fetchIncidents]);

  const handleSelectRow = useCallback((selectedCase) => {
    setSelectedPastCase(selectedCase);
  }, []);

  const handleClosePastCase = useCallback(() => {
    setSelectedPastCase(undefined);
  }, []);

  const renderMyRequests = useCallback(() => {
    if (loadingData) {
      return null; // No mostrar nada durante la carga
    }

    if (incidentsData.open.length + incidentsData.closed.length === 0) {
      return (
        <div className="mt-8">
          <AnnouncementSmall
            imgSrc="first_request.png"
            imgAlt="No incidents submitted yet"
            imgWidth="10em"
            title="No incidents submitted yet"
            subtitle="Start by submitting your first incident. Once created you will be able to see it in this session"
          />
        </div>
      );
    }

    return (
      <div className="horizontal-content">
        <div className="assigned-content" style={{ marginTop: "1em" }}>
          {incidentsData.open.map((incident) => (
            <MemoizedIncidentPost
              key={incident.case_id}
              incident={incident}
              onPostClose={() => {
                dispatchToast(
                  <Toast>
                    <ToastTitle>The {incident.classification} have been closed</ToastTitle>
                  </Toast>,
                  { intent: "success", duration: 2800, position: "top" }
                );
              }}
            />
          ))}
        </div>
      </div>
    );
  }, [loadingData, incidentsData.open]);

  return (
    <div className="two-view-content">
      <div className="my-requests-container responsive-post">
        <Toaster toasterId={toasterId} />
        <Subtitle2Stronger style={{ marginBottom: "1em" }}>Report new incident</Subtitle2Stronger>
        <MemoizedReportNewIncident onIncidentSubmitted={handleIncidentSubmitted} />
        {renderMyRequests()}
      </div>
      <div className="closed-request">
        <MemoizedMyPastList
          list={incidentsData.closed}
          groups={["Completed", "Canceled"]}
          groupType="status"
          onSelectRow={handleSelectRow}
          isAllDeselected={!selectedPastCase}
        />
        {selectedPastCase && (
          <MemoizedMyPastCaseDetails
            isOpen={Boolean(selectedPastCase)}
            selectedCase={selectedPastCase}
            onClose={handleClosePastCase}
          />
        )}
      </div>
    </div>
  );
}
