import { useState, useEffect, useContext, useCallback } from "react";
import { Button, Subtitle1, Caption1 } from "@fluentui/react-components";
import { PeopleCommunityAddRegular } from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../Context";
import useAxios from "../../../lib/useAxios";
import { generateRandomId } from "../../../lib/common";
import Announcement from "../../../components/Announcement";
import { MessageGroup } from "../../../components/MessageGroup";
import { TeamsDataList } from "./TeamsDataGrid";
import { TeamDetailsDialog } from "./TeamDetailsDialog";
import { TeamDeleteDialog } from "./TeamDeleteDialog";

export function Teams() {
  const { organizationApiUrl, usingMobileDevice } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);

  const [teams, setTeams] = useState([]);
  const [toDisplayTeams, setToDisplayTeams] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  const [isNewTeamDialogOpen, setIsNewTeamDialogOpen] = useState(false);
  const [selectedTeamToEdit, setSelectedTeamToEdit] = useState();
  const [selectedTeamToDelete, setSelectedTeamToDelete] = useState();

  useEffect(() => {
    setLoading(true);
    getTeams();
  }, []);

  useEffect(() => {
    applySearch();
  }, [searchText, teams]);

  const getTeams = async () => {
    try {
      const { data } = await axiosInstance.get("/org/teams");
      setTeams(data);
    } catch (e) {
      setMessages((prevMessages) => [
        {
          id: generateRandomId(8),
          intent: "error",
          title: "Oops!",
          body: `Some unexpected error has occurred and Teams could not be obtained. ${e.message}. Please, try again later`,
          dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
        },
        ...prevMessages,
      ]);
    } finally {
      setLoading(false);
    }
  };

  const applySearch = useCallback(() => {
    let result = teams;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.description?.toLowerCase().includes(searchText.toLowerCase()) ||
          item.profiles.join(",").toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setToDisplayTeams(result);
  }, [teams, searchText]);

  return (
    <div className="app-body inline-content">
      <div style={{ marginTop: "1em" }}>
        <MessageGroup messages={messages} />
      </div>

      {loading ? null : (
        <>
          <TeamDetailsDialog
            isOpen={isNewTeamDialogOpen}
            onClose={(refresh) => {
              setIsNewTeamDialogOpen(false);
              if (refresh) getTeams();
            }}
            mode="create"
            teams={teams}
          />
          {teams && teams.length === 0 ? (
            <>
              <div style={{ marginTop: "5%" }} />
              <Announcement
                imgSrc="teams.png"
                imgAlt="No teams were found for your organization"
                imgWidth="20em"
                title="No teams were found for your organization"
                subtitle="Teams allow grouping people according to their profile or skills to better manage incidents"
                actionComponent={
                  <div className="acction-msg-container">
                    <MessageGroup
                      messages={[
                        {
                          id: "warn",
                          intent: "warning",
                          title: "Warning:",
                          body: "Without teams, it won't be possible to automatically assign new incidents to technicians",
                        },
                      ]}
                    />
                    <Button appearance="primary" onClick={() => setIsNewTeamDialogOpen(true)}>
                      Create team
                    </Button>
                  </div>
                }
              />
            </>
          ) : (
            <div>
              <div className="flex-row" style={{ padding: "10px 0px" }}>
                <div style={{ display: "grid", gap: "5px" }}>
                  <Subtitle1>Technician Teams</Subtitle1>
                  <Caption1 style={{ color: "var(--colorNeutralForeground4)" }}>
                    Group people according to their profiles or skills to better manage incidents
                  </Caption1>
                </div>
              </div>
              <div className="root">
                <div className="assigned-content" style={{ marginBottom: "4em" }}>
                  <TeamDetailsDialog
                    isOpen={Boolean(selectedTeamToEdit)}
                    onClose={(refresh) => {
                      setSelectedTeamToEdit();
                      if (refresh) getTeams();
                    }}
                    mode="edit"
                    selectedTeam={selectedTeamToEdit}
                    teams={teams}
                  />
                  <TeamDeleteDialog
                    isOpen={Boolean(selectedTeamToDelete)}
                    onClose={(refresh) => {
                      setSelectedTeamToDelete();
                      if (refresh) getTeams();
                    }}
                    toDeleteTeam={selectedTeamToDelete}
                  />
                  <TeamsDataList
                    list={toDisplayTeams}
                    compactView={usingMobileDevice}
                    onEditTeam={setSelectedTeamToEdit}
                    onDeleteTeam={setSelectedTeamToDelete}
                    onSearch={(text) => setSearchText(text)}
                    actionComponent={
                      <Button
                        icon={<PeopleCommunityAddRegular />}
                        appearance="primary"
                        onClick={() => setIsNewTeamDialogOpen(true)}
                      >
                        Add new Team
                      </Button>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
