import { useState, useContext } from "react";
import {
  Persona,
  Menu,
  MenuButton,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuDivider,
  MenuGroupHeader,
  MenuItem,
  MenuItemRadio,
} from "@fluentui/react-components";
import {
  FlashRegular,
  bundleIcon,
  PeopleCommunityFilled,
  PeopleCommunityRegular,
  ArchiveRegular,
  ArchiveFilled,
  ArchiveArrowBackFilled,
  ArchiveArrowBackRegular,
  NextFrameFilled,
  NextFrameRegular,
  ClockPauseFilled,
  ClockPauseRegular,
  CircleFilled,
} from "@fluentui/react-icons";
import { TeamsFxContext } from "../../Context";
import useAxios from "../../lib/useAxios";
import { ReassignDialog } from "./ReassignDialog";
import { CloseDialog } from "./CloseDialog";
import { ReopenDialog } from "./ReopenDialog";

const TeamIcon = bundleIcon(PeopleCommunityFilled, PeopleCommunityRegular);
const CloseIcon = bundleIcon(ArchiveFilled, ArchiveRegular);
const PauseIcon = bundleIcon(ClockPauseFilled, ClockPauseRegular);
const ResumeIcon = bundleIcon(NextFrameFilled, NextFrameRegular);
const ReopenIcon = bundleIcon(ArchiveArrowBackFilled, ArchiveArrowBackRegular);

const availablePriorities = ["Critical", "High", "Moderate", "Normal"];

export function IncidentActions({ incident, availableTeams, orgTechnicians, onRefresh }) {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [selectedTeamIdToAssign, setSelectedTeamIdToAssign] = useState();
  const [selectedTechnicianIdToAssign, setSelectedTechnicianIdToAssign] = useState();
  const [isOpenCloseDialog, setIsOpenCloseDialog] = useState(false);
  const [isOpenReopenDialog, setIsOpenReopenDialog] = useState(false);

  const onSelectTechnician = (e, { name, checkedItems }) => {
    if (checkedItems.at(0) !== incident.assigned_msid) {
      setSelectedTechnicianIdToAssign(checkedItems.at(0));
    }
  };

  const onSelectTeam = (e, { name, checkedItems }) => {
    if (checkedItems.at(0) !== incident.team_id) {
      setSelectedTeamIdToAssign(checkedItems.at(0));
    }
  };

  const handleOnHoldResumeAction = async (newStatus) => {
    try {
      const { data: statusResponse } = await axiosUserInstance.put("/incidents/status", {
        case_id: incident.case_id,
        status: newStatus,
      });
      // Notify to requester/technician assigned
      const action = {
        name: newStatus === "On-hold" ? "hold" : "resume",
        notifications: statusResponse.notify.map((recipient) => ({
          recipient,
          title: `${
            newStatus === "On-hold"
              ? `⏸️ {actor} put the ${incident.classification} on hold`
              : `⏯️ {actor} resumed the ${incident.classification}`
          }`,
          preview: incident.subject,
        })),
      };
      onRefresh(action);
    } catch (e) {
      const errorMessage = e.response?.data?.detail || e.message || "An unexpected error occurred";
    }
  };

  const TechnicianSubMenu = ({ selectedTechnician, availableTechnicians, onSelectTechnician }) => {
    return (
      <Menu checkedValues={selectedTechnician} onCheckedValueChange={onSelectTechnician}>
        <MenuTrigger disableButtonEnhancement>
          <MenuItem>Specific Technician</MenuItem>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {availableTechnicians.map((technician) => (
              <MenuItemRadio key={technician.ms_id} name="techId" value={technician.ms_id}>
                <Persona
                  size="extra-small"
                  name={technician.display_name}
                  primaryText={technician.display_name}
                  avatar={{ color: "colorful" }}
                />
              </MenuItemRadio>
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  const onSelectPriority = (e, { name, checkedItems }) => {
    if (checkedItems.at(0) !== incident.priority) {
      handleChangePriority(checkedItems.at(0));
    }
  };

  const handleChangePriority = async (newPriority) => {
    try {
      await axiosUserInstance.put("/incidents/priority", {
        case_id: incident.case_id,
        priority: newPriority,
      });
      onRefresh({ name: "change_priority" });
    } catch (e) {
      const errorMessage = e.response?.data?.detail || e.message || "An unexpected error occurred";
      console.debug(errorMessage);
    }
  };

  return (
    <>
      <ReassignDialog
        isOpen={Boolean(selectedTeamIdToAssign) || Boolean(selectedTechnicianIdToAssign)}
        incident={incident}
        toAssignTeam={availableTeams.find((t) => t.id === selectedTeamIdToAssign)}
        toAssignTechnician={orgTechnicians.find((t) => t.ms_id === selectedTechnicianIdToAssign)}
        onClose={(refresh, action) => {
          setSelectedTeamIdToAssign();
          setSelectedTechnicianIdToAssign();
          if (refresh) onRefresh(action);
        }}
      />
      <CloseDialog
        isOpen={isOpenCloseDialog}
        incident={incident}
        onClose={(refresh, action) => {
          setIsOpenCloseDialog(false);
          if (refresh) onRefresh(action);
        }}
      />
      <ReopenDialog
        isOpen={isOpenReopenDialog}
        incident={incident}
        onClose={(refresh, action) => {
          setIsOpenReopenDialog(false);
          if (refresh) onRefresh(action);
        }}
      />
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton icon={<FlashRegular />}>Actions</MenuButton>
        </MenuTrigger>
        <MenuPopover>
          {["Open", "On-hold"].includes(incident.status) ? (
            <MenuList>
              {/* Reasign */}
              <Menu
                checkedValues={{ teamId: [incident.team_id] }}
                onCheckedValueChange={onSelectTeam}
              >
                <MenuTrigger disableButtonEnhancement>
                  <MenuItem>Reassign to</MenuItem>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    <MenuGroupHeader>Teams:</MenuGroupHeader>
                    {availableTeams.map((team) => (
                      <MenuItemRadio
                        key={team.id}
                        icon={<TeamIcon />}
                        name="teamId"
                        value={team.id}
                      >
                        {team.name}
                      </MenuItemRadio>
                    ))}
                  </MenuList>
                  <MenuDivider />
                  <TechnicianSubMenu
                    onSelectTechnician={onSelectTechnician}
                    availableTechnicians={orgTechnicians.filter(
                      (t) => t.ms_id !== incident.requester_msid
                    )}
                    selectedTechnician={{
                      techId: [incident.assigned_msid],
                    }}
                  />
                </MenuPopover>
              </Menu>
              {/* Priority */}
              <Menu
                checkedValues={{ priority: [incident.priority] }}
                onCheckedValueChange={onSelectPriority}
              >
                <MenuTrigger disableButtonEnhancement>
                  <MenuItem>Change priority</MenuItem>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    {availablePriorities.map((priority) => (
                      <MenuItemRadio
                        key={priority}
                        icon={<CircleFilled className={`dot-priority dot-priority-${priority}`} />}
                        name="priority"
                        value={priority}
                      >
                        {priority}
                      </MenuItemRadio>
                    ))}
                  </MenuList>
                </MenuPopover>
              </Menu>
              <MenuDivider />
              {incident.status === "Open" && (
                <MenuItem icon={<PauseIcon />} onClick={() => handleOnHoldResumeAction("On-hold")}>
                  Put on hold
                </MenuItem>
              )}
              {incident.status === "On-hold" && (
                <MenuItem icon={<ResumeIcon />} onClick={() => handleOnHoldResumeAction("Open")}>
                  Resume {incident.classification}
                </MenuItem>
              )}
              <MenuItem icon={<CloseIcon />} onClick={() => setIsOpenCloseDialog(true)}>
                Close {incident.classification}
              </MenuItem>
            </MenuList>
          ) : (
            <MenuList>
              <MenuItem icon={<ReopenIcon />} onClick={() => setIsOpenReopenDialog(true)}>
                Reopen {incident.classification}
              </MenuItem>
            </MenuList>
          )}
        </MenuPopover>
      </Menu>
    </>
  );
}
