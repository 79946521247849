import { useEffect, useContext } from "react";
import { Button, Spinner, Field } from "@fluentui/react-components";
import { Payment20Regular } from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../../Context";
import config from "../../../../lib/config";
import useAxios from "../../../../lib/useAxios";
import { useConfigWebSocket } from "../../../../lib/useConfigWebSocket";
import { getWSConfigClientId } from "../../../../lib/common";

export const SetupPaymentMethod = ({ ssoToken, cardPreview, loading, updateCardPreview }) => {
  const { updateActivePlan } = useContext(TeamsFxContext);
  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, true);

  const clientId = getWSConfigClientId(ssoToken);
  // Subscribe to clientId queue
  const { lastMessage } = useConfigWebSocket(
    `${config.hubEndpoint.replace("https", "wss")}/ws/connect`,
    clientId
  );

  useEffect(() => {
    if (lastMessage && lastMessage.type === "stripe_back") {
      if (lastMessage.registered) {
        updateCardPreview(
          {
            card4: lastMessage.card_last4,
            brand: lastMessage.card_brand,
          },
          false
        );
        updateActivePlan(true);
      } else {
        updateCardPreview(undefined, false);
      }
    }
  }, [lastMessage]);

  const setUpPaymentMethod = async () => {
    try {
      updateCardPreview(undefined, true);
      const { data } = await axiosHubInstance.put("/billing/setup/session");
      window.open(data.url, "_blank");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="payment-method payment-method-update-container">
      <div className="summary-container">
        <div className="payment-method-summary">
          <b>Payment method</b>
          {cardPreview.configured === true && (
            <>
              <Field
                label=""
                validationState="success"
                validationMessage={cardPreview.brand.toUpperCase()}
              >
                <span>💳 •••• {cardPreview.card4}</span>
              </Field>
              <Button
                style={{ marginTop: "1em" }}
                appearance="subtle"
                icon={loading ? <Spinner size="extra-tiny" /> : <Payment20Regular />}
                onClick={setUpPaymentMethod}
              >
                Change Payment Method
              </Button>
            </>
          )}
          {cardPreview.configured === false && (
            <>
              <p>No payment method has been configured yet.</p>
              <Button
                appearance="primary"
                icon={loading ? <Spinner size="extra-tiny" /> : <Payment20Regular />}
                onClick={setUpPaymentMethod}
              >
                Set Up Payment Method
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
