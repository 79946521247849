import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  RadioGroup,
  Radio,
} from "@fluentui/react-components";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId } from "../../../../lib/common";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";

export const TagDeleteDialog = ({ isOpen, onClose, toDeleteTag }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [messages, setMessages] = useState([]);
  const [tagName, setTagName] = useState();
  const [deleteOption, setDeleteOption] = useState();

  useEffect(() => {
    if (isOpen) {
      setTagName(toDeleteTag?.value);
    } else {
      setTimeout(() => {
        setTagName("");
        setDeleteOption();
        setMessages([]);
      }, 500);
    }
  }, [toDeleteTag]);

  const handleDeleteAction = async () => {
    try {
      await axiosUserInstance.delete(
        `/org/tags/${toDeleteTag?.id}?permanantly=${deleteOption === "exclude"}`
      );
      onClose(true);
    } catch (e) {
      const bodyMsg = `Some unexpected error has occurred and Tag could not be deleted. ${e.message}. Please, try again later`;
      if (!messages.some((m) => m.body === bodyMsg)) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: bodyMsg,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <MessageGroup messages={messages} />
        <DialogBody>
          <DialogTitle>Delete tag "{tagName}"?</DialogTitle>
          <DialogContent>
            <p>
              You're about to delete the Tag "{tagName}". Please choose one of the following
              options:
            </p>
            <RadioGroup
              value={deleteOption}
              onChange={(_, data) => {
                setDeleteOption(data.value);
              }}
            >
              <Radio value="exclude" label="Delete and exclude from future classifications" />
              <Radio
                value="no-exclude"
                label="Delete, but allow to be included in future classifications"
              />
            </RadioGroup>
            <p>
              <MessageGroup
                animate={false}
                messages={[
                  {
                    id: "3333",
                    intent: "warning",
                    title: "Warning:",
                    body: "this action cannot be undone and could affect the tags assigned to some incidents.",
                  },
                ]}
              />
            </p>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={() => onClose(false)}>
              Cancel
            </Button>
            <Button disabled={!deleteOption} appearance="primary" onClick={handleDeleteAction}>
              Delete
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
