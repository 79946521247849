import { useContext } from "react";
import { Signup } from "../screens/NotConfiguredApp/Signup";
import { TeamsFxContext } from "../Context";
import { UserView } from "../screens/UserView";

/**
 * The main view Tab which handles App logic
 */
export default function Tab() {
  const { themeString, organizationStatus } = useContext(TeamsFxContext);

  return (
    <div
      className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}
    >
      {organizationStatus === "not_created" && <Signup />}
      {organizationStatus === "active" && <UserView />}
    </div>
  );
}
