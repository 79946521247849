import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Link,
  Body1,
  Caption1,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
} from "@fluentui/react-components";
import {
  ErrorCircle20Regular,
  ShareScreenPerson24Regular,
  Share24Filled,
  MoreHorizontal20Regular,
} from "@fluentui/react-icons";

const Content = React.memo(({ value }) => {
  const boxStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  };

  return (
    <div style={boxStyle}>
      {typeof value === "string" ? (
        <span>{value}</span>
      ) : (
        React.cloneElement(value, { style: { width: "100%", height: "100%" } })
      )}
    </div>
  );
});

export function BillingHistory() {
  return (
    <div className="app-body">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Card
          style={{
            width: "75%",
            margin: "15px",
            padding: "15px",
          }}
        >
          <CardHeader
            header={
              <Body1>
                <b>{"widget.title"}</b>
              </Body1>
            }
            description={<Caption1>{"widget.desc"}</Caption1>}
            action={
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <MenuButton
                    appearance="transparent"
                    icon={<MoreHorizontal20Regular />}
                  ></MenuButton>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    <MenuItem icon={<ErrorCircle20Regular />}>icon</MenuItem>
                    <MenuItem icon={<ShareScreenPerson24Regular />}>Popup</MenuItem>
                    <MenuItem icon={<Share24Filled />}>Share</MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
            }
          />

          <Content value={"widget.body" || ""} />
          <CardFooter>
            <Link href={"widget.link?.href"}>View more</Link>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
