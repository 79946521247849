import { useState, useRef } from "react";
import { Tab, TabList } from "@fluentui/react-components";
import { AllIncidentsTabContent } from "./AllIncidentsTabContent";
import { AssignedTabContent } from "../../TechnicianScreen/AssignedTabContent";
import { RequesterScreen } from "../../RequesterScreen";
import { haveRole } from "../../../lib/common";

export function Incidents() {
  const [selectedIncidentsTab, setSelectedIncidentsTab] = useState("myRequestTab"); // TODO: use last selected value
  const tab = useRef("myRequestTab");
  const isAdminTechnician = haveRole("technician");

  return (
    <div className="app-body">
      <div className="tab-container">
        <TabList
          defaultSelectedValue={tab.current}
          onTabSelect={(_, { value }) => setSelectedIncidentsTab(value)}
        >
          <Tab value="myRequestTab">My requests</Tab>
          {isAdminTechnician && <Tab value="assignedTab">Assigned</Tab>}
          <Tab value="allIncidentsTab">All Incidents</Tab>
        </TabList>
      </div>
      <div className="tab-content">
        {selectedIncidentsTab === "allIncidentsTab" && <AllIncidentsTabContent />}
        {isAdminTechnician && selectedIncidentsTab === "assignedTab" && <AssignedTabContent />}
        {selectedIncidentsTab === "myRequestTab" && <RequesterScreen />}
      </div>
    </div>
  );
}
